<section class='container my-4'>
 <div class='d-flex justify-content-center row'>
  <div class='col-lg-6'>
   <img class='center' src='assets/img/404.png' alt='Error 404 pagina no encontrada' id='imgError'>
  </div>
  <div class='col-lg-5 d-flex flex-column justify-content-center' id='ctnText404'>
   <h1 class='text-center'>404</h1>
   <h4 class='text-center'>Lo sentimos, no hemos podido encontrar la página que buscas.</h4>
   <button class='btn btnAseg mt-4 text-uppercase' type='button' (click)='goHome()'>Volver al inicio</button>
  </div>
    
 </div>
</section>
