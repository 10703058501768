import { Component, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { FormVehComponent } from './auto/form-veh/form-veh.component';
import { ViewChildCompService } from './auto/services/view-child-comp.service';
import { DataService } from './auto/services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  

  title = 'asegurate';
  showMenu:boolean=true;
  whatsUs:string='https://api.whatsapp.com/send?phone=18096202524&text=Hola!, necesito tu ayuda con el proceso para asegurarme en ASEGURATE.COM.DO';
  
  serviceA:any;

  constructor( 
    public dataService: DataService,
    public router: Router,    
    public location: Location,
    private listComp: ViewChildCompService 
  ) { }    
    

  ngOnInit(): void {
    if(sessionStorage.getItem('raload')=== 'true'){
     this.resetEl(); 
    }
    if(this.location.path().slice(0, 11)=='/inspeccion'){
      this.showMenu =false;
    }
  } 

  @ViewChild(FormVehComponent) inpsViewChild: FormVehComponent;
  ngAfterViewInit(){
   // console.log("aaaawee")
    this.dataService.Interaccion(0,"Se entro a la pantalla de Productos (Home)", 0)
    if(sessionStorage.getItem('raload')=== 'true'){
      this.resetEl(); 
     }
    if(this.location.path().slice(0, 11)=='/inspeccion'){
      this.showMenu =false;
    }
  }
  resetEl(){
    sessionStorage.removeItem('raload');
    location.reload();  
  }

  

  //Go wassap
goWhatapp(){
  window.open(this.whatsUs, "_blank");
 }
}
