import { Component, Input, OnInit } from '@angular/core';
import{HttpClient} from '@angular/common/http';
import Typewriter from 't-writer.js'
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DataService } from 'src/app/auto/services/data.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  @Input() carData: any;
  submitted = false
  servPrinc:any
  servPeq:any
  emailForm: FormGroup;
  isSubmitting = true;
  lazyLoading:boolean=true;
  DireccionIp: string

  
  constructor(
    private formBuilder: FormBuilder, 
    private http:HttpClient,
    private dataService: DataService
  ) { }
/*  ngOnInit(): void {  
    this.http.get(environment.baseURL+'/Productos').subscribe(
      data =>{
        JSON.parse(JSON.stringify(data))
      //  console.log(typeof(data))
        this.lazyLoading=false;
        this.servPrinc=Array.of(data[0]);
        delete data[0];
        this.servPeq=Object.keys(data);
       // console.log(data);
      //  console.log(this.servPrinc);
      //  console.log(this.servPeq);
      },
      error => {
      //  console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: 'Algo salio mal, por favor vuelva a intentarlo.<br>'+
          '<br>Si el error persiste por favor comuniquese con nosotros al 809-620-2524 o '+
          '<a href="https://api.whatsapp.com/send?phone=18096202524&text=Hola, no he podido ver los servicios disponibles en el incio de la web ASEGURATE.COM.DO y necesito su asistencia." target="_blank">escríbenos a WhatsApp</a>'
        })
      }
    );*/
      
  ngOnInit(): void {  
    //console.log(this.dataService.allTheData)
    this.http.get(environment.baseURL+'/Productos').subscribe({
      next: (data:any) => {
        JSON.parse(JSON.stringify(data));
        this.lazyLoading=false;
        this.servPrinc=Array.of(data[0]);
        this.servPeq=Array.from(data.slice(1));
      },
      error: error => {
       // console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: 'Algo salio mal, por favor vuelva a intentarlo.<br>'+
          '<br>Si el error persiste por favor comuniquese con nosotros al 809-620-2524 o '+
          '<a href="https://api.whatsapp.com/send?phone=18096202524&text=Hola, no he podido ver los servicios disponibles en el incio de la web ASEGURATE.COM.DO y necesito su asistencia." target="_blank">escríbenos a WhatsApp</a>'
        })
      }
    });
      
      /*.subscribe((data:any)=>{
    this.lazyLoading=false;
    this.servPrinc=Array.of(data[0]);
    this.servPeq=Array.from(data.slice(1));
  }
  );*/

  // Writing efect
  const target = document.querySelector('.tw');
  const writer = new Typewriter(target, {
    loop: true,
    typeSpeed: 80,
    deleteSpeed: 80,
    typeColor: '#1F1E1E',
    cursorColor: 'white'
  })
  writer
    .type('ESTAR BIEN CUBIERTO')
    .rest(500)
    .changeOps({ deleteSpeed: 80 })
    .remove(19)
    .type('VER TODAS LAS OPCIONES')
    .rest(500)
    .remove(16)
    .changeOps({ deleteSpeed: 20 })
    .start()
  } 

  	//ir al formulario
  goToform(val:any){
    window.open(val, '_self');
  }

  


  

}
