<section class='container my-lg-4 px-3'>
 
 <div>
 
  <div class='row px-md-3'>
   <div>
     <h1 class='regBold' id='titlePregResp'>PREGUNTAS Y RESPUESTAS</h1>
   </div>
   <nav id='navbarPregResp' class='navbar navbar-light px-3 fixed'>
    <ul class='nav nav-pills' id='ctnHash'>
      <li class='nav-item'>
       <a class='nav-link' (click)='showJustMe("aseg")'>#Sobre Asegúrate</a>
      </li>
      <li class='nav-item'>
        <a class='nav-link' (click)='showJustMe("proc")'>#Proceso</a>
      </li>
      <li class='nav-item'>
        <a class='nav-link' (click)='showJustMe("cobPol")'>#Cobertura/Póliza</a>
      </li>
      <li class='nav-item'>
       <a class='nav-link' (click)='showJustMe("pago")'>#Pagos</a>
      </li>
      <li class='nav-item'>
       <a class='nav-link' (click)='showJustMe("cancel")'>#Cancelaciones</a>
      </li>
      <li class='nav-item'>
       <a class='nav-link' (click)='showJustMe("reclam")'>#Reclamaciones</a>
      </li>
      <li class='nav-item'>
       <a class='nav-link' (click)='showJustMe("all")' *ngIf='seeAll'>#Todo</a>
      </li>
    </ul>
  </nav>

  <div class='mb-4'>
   
   <div id='sobAsg' *ngIf='asegurate' [ngClass]='{"slideInRight": asegurate && clasSh, "slideInLeft": clasSh && !seeAll}'>
    <h4 >Sobre Asegúrate</h4>
    <!-- ¿Qué es Asegúrate y que servicios ofrece? -->
    <div class='mb-2 mt-3'>
     <p class='regBold mb-1'>¿Qué es Asegúrate y cuáles servicios ofrece?</p>
     <p>Asegurate.do te permite cotizar diferentes tipos de seguros y obtener las primas entre varias aseguradoras en una sola búsqueda, para asegurarte en minutos.<br>A través de nuestra integración y gestión completamente digital, te brindamos facilidad, conveniencia y seguridad.<br>Se trata de una herramienta fácil, transparente y completamente imparcial que simplifica la contratación de tu póliza de seguros para que en pocos minutos puedas ver todas las ofertas y elegir la que mejor se adapte a lo que necesites.<br>Con el respaldo de socios de confianza y renombre, Cotiza y Asegúrate tranquilo.</p>
    </div>
    <!-- ¿Por qué más elegirnos? -->
    <div class='my-2'>
     <p class='regBold mb-1'>¿Por qué más elegirnos?</p>
     <ul>
      <li><span class='regBold'>Siempre accesibles.</span><br>Ofrecemos información, cotizaciones o asistencia en reclamaciones a cualquier hora y desde distintos canales digitales como móvil, chat y redes sociales.</li>
      <li><span class='regBold'>Todo al alcance de tu mano.</span><br>Web adaptada a dispositivos móviles para una mejor experiencia de usuario.</li>
      <li><span class='regBold'>Soluciones de seguros adaptadas a tus necesidades.</span><br>Planes con coberturas robustas y flexibles, centrados en la necesidades personales de protección.</li>
     </ul>
    </div>
    <!-- ¿Qué tan seguro es proporcionar mis datos esta página web? -->
    <div class='my-2'>
     <p class='regBold mb-1'>¿Qué tan seguro es proporcionar mis datos esta página web?</p>
     <p>La información que nos proporcionas es justo la necesaria para poder ofrecerte la mejor cotización posible.</p>
    </div>
    <!-- ¿Datos y calificaciones de seguridad del sistema? -->
    <div class='my-2'>
     <p class='regBold mb-1'>¿Datos y calificaciones de seguridad del sistema?</p>
     <p>Nuestra página web es segura, contamos con los protocolos de seguridad de la información para cuidar tus datos y mantenerlos protegido. No guardamos datos confidenciales como números de tarjeta de crédito ni compartimos tus datos a terceros.</p>
    </div>
    <!-- ¿Y si tengo dudas después de contratar un producto? -->
    <div class='my-2'>
     <p class='regBold mb-1'>¿Y si tengo dudas después de contratar un producto?</p>
     <p>En Asegurate.do cotizas, emites tu póliza y puedes además hacer un reporte de reclamo, todo en línea. Si tienes dudas en el proceso, o después de adquirir un producto o servicio, puedes comunicarte con nosotros <span class='regBold'>809-620-2524</span></p>
    </div>
   </div>

   <div id='ctnPro' *ngIf='proceso' [ngClass]='{"slideInRight": proceso && clasSh, "slideInLeft": clasSh && !seeAll}'>
    <h4>Proceso</h4>
    <!-- ¿Necesito estar registrado o tener cuenta para poder asegurarme? -->
    <div class='mb-2 mt-3'>
     <p class='regBold mb-1'>¿Necesito estar registrado o tener cuenta para poder asegurarme?</p>
     <p>No necesitas estar registrado. Cotiza, Contrata y Asegúrate.</p>
    </div>
    <!-- ¿Cómo es el proceso para asegurarme?  -->
    <div class='my-2'>
     <p class='regBold mb-1'>¿Cómo es el proceso para asegurarme? </p>
     <p class='mb-1'><span class='text-decoration-underline'>Cotiza, contrata y Asegúrate.</span><br></p>
     <ul>
      <li>Contesta unas preguntas</li>
      <li>Respondes unas sencillas preguntas sobre tu perfil y sobre el producto que necesitas para adaptar la búsqueda.</li>
      <li>Accedes a las ofertas disponibles para ti y elije entre las mejores opciones del mercado.</li>
      <li>Tú eliges. Te mostramos las ofertas de las compañías de manera imparcial. Puedes contratar en pocos minutos, desde donde estés. </li>
     </ul>
    </div>
    <!-- ¿Qué pasa si no me llegó mi póliza después de la compra? -->
    <div class='mb-2 mt-3'>
     <p class='regBold mb-1'>¿Qué pasa si no me llegó mi póliza después de la compra?</p>
     <p>Si no recibiste el correo de confirmación con tu póliza en formato PDF, por favor revisa la carpeta de correo no deseado. Si el problema persiste, puedes comunicarte con nosotros <span class='regBold'>809-620-2524</span>.</p>
    </div>
    <!-- Qué documentación necesito tener a mano para obtener un seguro de vehículo o Motor?  -->
    <div class='mb-2 mt-3'>
     <p class='regBold mb-1'>¿Qué documentación necesito tener a mano para obtener un seguro de vehículo o Motor? </p>
     <p class='mb-1'>Si quieres obtener las mejores pólizas seguros de vehículo solo tienes que responder las preguntas al inicio, marca, modelo, año, valor, uso y tipo de seguro que buscas.</p>
     <p class='mb-1'>Para emitir el seguro, una vez ya hayas elegido la aseguradora, necesitas tener a mano tu cedula, licencia, matriculo o conduce y tener acceso al vehículo para subir las fotos de inspección.</p>
     <p class='mb-1'>Para motores, tenemos disponibles seguros de ley, solo tienes que indicarlo en las preguntas al inicio para cotizar, ¡contratar y estar asegurado!</p>
     
    </div>
    <!-- >No encuentro mi vehículo entre las opciones que ofrecen, ¿qué hago? -->
    <div class='mb-2 mt-3'>
     <p class='regBold mb-1'>No encuentro mi vehículo entre las opciones que ofrecen, ¿qué hago?</p>
     <p>Comprueba en la matrícula de tu vehículo el modelo exacto. Si sigues sin encontrarlo entre las opciones disponibles ponte en contacto con nosotros y te ayudaremos a hacer la cotización. <span class='regBold'>809-620-2524</span></p>
    </div>
    <!-- ¿Si entro todos los datos requeridos y no arroja ningún resultado, que significa? -->
    <div class='mb-2 mt-3'>
     <p class='regBold mb-1'>¿Si entro todos los datos requeridos y no arroja ningún resultado, que significa?</p>
     <p>Si al ingresar tus datos no arroja ningún resultado cotizado, favor revisar nuevamente la información suministrada. En caso de que no haya error en la digitación de esta, ponte en contacto con nosotros y te ayudaremos a hacer la cotización. <span class='regBold'>809-620-2524</span></p>
    </div>
    <!-- Si me equivoco al introducir los datos, ¿puedo modificarlos o tengo que hacer la cotización de nuevo?  -->
    <div class='mb-2 mt-3'>
     <p class='regBold mb-1'>Si me equivoco al introducir los datos, ¿puedo modificarlos o tengo que hacer la cotización de nuevo?</p>
     <p class='mb-1'>Si al hacer una cotización de seguros te equivocas al dar algún dato o quieres modificar/añadir, solo tienes que regresar a la página inicial y editar la información.</p>
     <p class='mb-1'>Si te das cuenta del error luego de haber finalizado los pasos para la emisión de la póliza, por favor contáctanos <span class='regBold'>809-620-2524</span>.</p>
    </div>
    <!-- ¿Dónde está el chasis? -->
    <div class='mb-2 mt-3'>
     <p class='regBold mb-1'>¿Dónde está el chasis?</p>
     <p>El número de identificación del vehículo (VIN) o el número de chasis es un código único que incluye el número de serie otorgado por el fabricante para identificar los autos y otros vehículos.</p>
     <ol>
      <li>La base del vidrio delantero, cerca del lado del conductor.</li>
      <li>El marco del auto, cerca del contenedor del líquido del limpiaparabrisas.</li>
      <li>La jamba de la puerta del conductor.</li>
      <li>Debajo del neumático de repuesto.</li>
     </ol>
    </div>
   </div>

   <div id='cobPol' *ngIf='cobPol' [ngClass]='{"slideInRight": cobPol && clasSh, "slideInLeft": clasSh && !seeAll}'>
    <h4>Cobertura/Póliza</h4>
    <!-- ¿Cuánto tiempo tardo en estar cubierto una vez aprobada mi póliza? -->
    <div class='my-2'>
     <p class='regBold mb-1'>¿Cuánto tiempo tardo en estar cubierto una vez aprobada mi póliza?</p>
     <p class='mb-1'>Estarás cubierto 24 horas después de la confirmación de la aceptación del riesgo y la emisión de la póliza con el pago correspondiente.</p>
     <p class='mb-1'>Es importante considerar que, dependiendo del producto, existen términos y condiciones distintas.</p>
    </div>
    <!-- ¿Qué debo hacer para renovar mi póliza? -->
    <div class='mb-2 mt-3'>
     <p class='regBold mb-1'>¿Qué debo hacer para renovar mi póliza?</p>
     <p>Todas las pólizas se renuevan automáticamente, a menos que decidas cancelar tu cobertura.</p>
    </div>    
    <!-- ¿Está la aseguradora obligada a asegurarme?  -->
    <div class='my-2'>
     <p class='regBold mb-1'>Las aseguradoras al efectuar su proceso de evaluación de riesgo, se reservan el derecho de aceptación de este. En caso de que la aseguradora seleccionada decline el riesgo y la emisión de la póliza, te notificamos de inmediato para que escojas otra aseguradora que haya presentado cotización.</p>
     <p></p>
    </div>
   </div>

   <div id='pagos' *ngIf='pagos' [ngClass]='{"slideInRight": pagos && clasSh, "slideInLeft": clasSh && !seeAll}'>
    <h4>Pagos</h4>
    <div class='mb-2 mt-3'>
     <!-- ¿Cuáles opciones de pagos tienen?  -->
     <p class='regBold mb-1'>¿Cuáles opciones de pagos tienen? </p>
     <p>Puedes pagar con tarjeta de crédito.</p>
    </div>
    <!-- ¿Cómo puedo actualizar mi método de pago? -->
    <div class='my-2'>
     <p class='regBold mb-1'>¿Cómo puedo actualizar mi método de pago?</p>
     <p>Para cambiar tu método de pago comunícate con nosotros <span class='regBold'>809-620-2524</span></p>
    </div>
    <!-- ¿Cuál es mi fecha límite de pago? -->
    <div class='my-2'>
     <p class='regBold mb-1'>¿Cuál es mi fecha límite de pago?</p>
     <p>Tu fecha límite de pago dependerá del día en el que hayas contratado la póliza, el producto seleccionado y la frecuencia de pago que corresponda.</p>
    </div>
   </div>

   <div id='cancl' *ngIf='cancel' [ngClass]='{"slideInRight": cancel && clasSh, "slideInLeft": clasSh && !seeAll}'>
    <h4>Cancelaciones</h4>
    <!-- ¿Cómo puedo cancelar mi póliza? -->
    <div class='mb-2 mt-3'>
     <p class='regBold mb-1'>¿Cómo puedo cancelar mi póliza?</p>
     <p>Si no quieres renovar tu póliza debes cancelarla comunicándote con <span class='regBold'>809-620-2524</span></p>
    </div>
   </div>

   <div id='ctnPro' *ngIf='reclam' [ngClass]='{"slideInRight": reclam && clasSh, "slideInLeft": clasSh && !seeAll}'>
    <h4>Reclamaciones</h4>
    <!-- ¿Como tramito un reclamo?  -->
    <div class='my-2'>
     <p class='regBold mb-1'>¿Cómo tramito un reclamo? </p>
     <p class='mb-1'>Cuando tengas una eventualidad, haces clic en el icono de RECLAMOS y sigue las instrucciones. Una vez que ingreses la información y documentos solicitados, cuando completes el proceso recibirás un correo de confirmación con el número de reclamación asignado.</p>
     <p class='mb-1'>Si tienes dudas en el proceso, o prefieres hablar con un ejecutivo, puedes comunicarte con nosotros  <span class='regBold'>809-620-2524</span></p>
     <p class='mb-1'>Te proveemos acompañamiento y soporte en todo el proceso. Una vez cumples todos los requisitos de información necesaria, nos encargamos de velar por tus intereses frente a las aseguradoras.</p>
    </div>
    <!-- Documentos Necesarios en caso de accidente en tu vehículo  -->
    <div class='my-2'>
     <p class='mb-0 regBold'>Documentos necesarios en caso de accidente en tu vehículo.</p>
     <ul>
      <li>Acta policial certificada.</li>
      <li>Licencia.</li>
      <li>Cédula.</li>
      <li>Presupuesto de reparación.</li>
      <li>Presentar vehículo para evaluar daños (sí puede movilizarse, sino avisar donde se puede ir a ver).</li>
      <li>Copia de matrícula.</li>
      <li>Reporte de accidente debidamente llenado.</li>
      <li>Otros (a indicarse según sea el caso).</li>
     </ul>
    </div>
   </div>

  </div>

  </div>
 </div>
</section>