import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/auto/services/data.service';
@Component({
  selector: 'app-preg-resp',
  templateUrl: './preg-resp.component.html',
  styleUrls: ['./preg-resp.component.css']
})
export class PregRespComponent implements OnInit {

  //variable para mostrar las preguntas y respuestas por hashtag
  asegurate:boolean = true;
  proceso:boolean = true;
  cobPol:boolean = true;
  pagos:boolean = true;
  cancel:boolean = true;
  reclam:boolean = true;
  seeAll:boolean = false;
  clasSh:boolean = false;

  constructor(private http: HttpClient, private dataServices: DataService) { }

  ngOnInit(): void {
    this.dataServices.Interaccion(0,"Se selecciono el apartado de Preguntas y Respuestas",0)
  }

  showJustMe(eleme:string){
    switch(eleme){
      case 'aseg':{
        this.seeAll= true;
        this.clasSh=true;
        this.asegurate = true;
        this.proceso = false;
        this.cobPol = false;
        this.pagos = false;
        this.cancel = false;
        this.reclam = false;
        break;
      }
      case 'proc':{
        this.seeAll= true;
        this.clasSh=true;
        this.asegurate = false;
        this.proceso = true;
        this.cobPol = false;
        this.pagos = false;
        this.cancel = false;
        this.reclam = false;
        break;
      }
      case 'cobPol':{
        this.seeAll= true;
        this.clasSh=true;
        this.asegurate = false;
        this.proceso = false;
        this.cobPol = true;
        this.pagos = false;
        this.cancel = false;
        this.reclam = false;
        break;
      }
      case 'pago':{
        this.seeAll= true;
        this.clasSh=true;
        this.asegurate = false;
        this.proceso = false;
        this.cobPol = false;
        this.pagos = true;
        this.cancel = false;
        this.reclam = false;
        break;
      }
      case 'cancel':{
        this.seeAll= true;
        this.clasSh=true;
        this.asegurate = false;
        this.proceso = false;
        this.cobPol = false;
        this.pagos = false;
        this.cancel = true;
        this.reclam = false;
        break;
      }
      case 'reclam':{
        this.seeAll= true;
        this.clasSh=true;
        this.asegurate = false;
        this.proceso = false;
        this.cobPol = false;
        this.pagos = false;
        this.cancel = false;
        this.reclam = true;
        break;
      }
      case 'all':{
        this.seeAll= false;
        this.clasSh=true;
        this.asegurate = true;
        this.proceso = true;
        this.cobPol = true;
        this.pagos = true;
        this.cancel = true;
        this.reclam = true;
        break;
      }
    }
  }
}
