import {AbstractControl} from '@angular/forms';

export class myValidations{

 //Validar la fecha de vencimiento no sea menor al mes actual o el año actual
 static correctExp(control:AbstractControl){
  const tday:any= new Date(); //Tome la fecha
  const exp = control.value; // el value de exp
  let tyear = tday.getFullYear(); //tomo el año de la fecha
  let shortYear = tyear.toString().substr(-2);//Quito los dos primeros numeros
  let validador = (tday.getMonth() + 1); // Tomo el mes
   let mm= exp.substring(0,2); //del campo exp tomo el mes
   let aa= exp.substring(2,4);//del campo exp tomo el año
   if((aa<shortYear || (aa==shortYear && mm <= validador) || mm >=13)&&control.value!==''){
    return{correctExp:true};
   } 
   return null;
 }

 static whitespace(control:AbstractControl) {
   if(control.value){
     const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
   }
  return null;
}




}