<section class='container mt-lg-4 px-3'>
 <div class='row px-md-3'>
  <div>
    <h1 class='regBold'>POLÍTICA DE COOKIES</h1>
  </div>
  <!-- ¿Qué es una cookie y para qué sirve? -->
  <div class='mt-4 mb-2 px-lg-4 px-2'>
   <p class='regBold'>¿Qué es una cookie y para qué sirve?</p>
   <p>Una 'cookie' es un pequeño archivo que se almacena en el ordenador del usuario, tablet, smartphone o cualquier otro dispositivo con información sobre la navegación. El conjunto de 'cookies' de todos nuestros usuarios nos ayuda a mejorar la calidad de nuestra web, permitiéndonos controlar qué páginas son útiles, cuáles no y cuáles son susceptibles de mejora.<br>Las cookies son esenciales para el funcionamiento de Internet, aportando innumerables ventajas en la prestación de servicios interactivos, facilitándole la navegación y usabilidad de nuestra web.<br>En ningún caso las cookies podrían dañar su equipo. Por lo contrario, el que estén activas nos ayuda a identificar y resolver los errores.</p>
  </div>
  <!-- Cookies utilizadas en este sitio web -->
  <div class='my-2 px-lg-4 px-2'>
   <p class='regBold'>Cookies utilizadas en este sitio web</p>
   <p class='mb-0'>Con el fin de informarle con la máxima exactitud posible.<br>Este sitio web utiliza las siguientes <span class='regBold'>cookies propias:</span></p>
   <ul>
    <li>Cookies de sesión, para garantizar que los usuarios se han suscrito a nuestro newsletter.</li>
   </ul>
   <p class='mb-0'>Este sitio web utiliza las siguientes <span class='regBold'>cookies de terceros:</span></p>
   <ul>
    <li>Google Analytics: Almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas de esta web. Al utilizar este sitio web está consintiendo el tratamiento de información acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido deberá hacerlo comunicando directamente con Google.</li>
   </ul>
   <p class='regBold mb-0'>Notas adicionales</p>
   <ul>
    <li>Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad que puedan tener los terceros mencionados en esta política de cookies.</li>
    <li>Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe efectuar su derecho a eliminación o desactivación de las mismas. Ni esta web ni sus representantes legales pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados navegadores.</li>
    <li>En algunos casos es necesario instalar cookies para que el navegador no olvide su decisión de no aceptación de las mismas.</li>
    <li>En el caso de las cookies de Google Analytics, esta empresa almacena las cookies en servidores ubicados en Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal efecto. Según Google no guarda su dirección IP. Google Inc. es una compañía adherida al Acuerdo de Puerto Seguro que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la normativa europea. </li>
   </ul>
  </div>
  <!-- ¿Cómo puedes borrar o eliminar las cookies? -->
  <div class='my-2 px-lg-4 px-2'>
   <p class='regBold'>¿Cómo puedes borrar o eliminar las cookies?</p>
   <p>El usuario tiene la opción de permitir, bloquear o eliminar las 'cookies' instaladas en su equipo mediante la configuración de las opciones del navegador ¡Aviso importante! En el caso de que se bloqueen las cookies, es posible que haya servicios o información que no estén disponibles.</p>
  </div>
  <!-- Para configurar los permisos relacionados con las 'cookies' en los navegadores: -->
  <div class='my-2 px-lg-4 px-2'>
   <p class='regBold'>Para configurar los permisos relacionados con las 'cookies' en los navegadores:</p>
   <p class='regBold mb-0'>Google Chrome:</p>
   <ol>
    <li>Hacer clic en el menú situado en la barra de herramientas.</li>
    <li>Seleccionar Configuración.</li>
    <li>Hacer clic en Mostar opciones avanzadas.</li>
    <li>En la sección 'Privacidad' hacer clic en el botón Configuración de contenido.</li>
    <li>En la sección de 'Cookies' se pueden configurar las opciones.</li>
   </ol>
   <p class='regBold mb-0 mt-2'>Mozilla Firefox:</p>
   <ol>
    <li>En la parte superior de la ventana de Firefox hacer clic en el menú Herramientas.</li>
    <li>Seleccionar Opciones.</li>
    <li>Seleccionar en panel Privacidad.</li>
    <li>En la opción Firefox podrá elegir Usar una configuración personalizada para el historial para configurar las opciones.</li>
   </ol>
   <p class='regBold mb-0'>Microsoft Edge:</p>
   <ol>
    <li>Selecciona "Configuración y más" > Configuración > Privacidad, búsqueda y servicios .</li>
    <li>En Borrar datos de exploración, selecciona "Elegir lo que se debe borrar".</li>
    <li>En Intervalo de tiempo, elige un intervalo de tiempo.</li>
    <li>Selecciona Cookies y otros datos del sitio y luego Borrar ahora.</li>
   </ol>
   <p class='regBold mb-0'>Safari:</p>
   <ol>
    <li>Ve a Configuración > Safari y toca "Borrar historial y datos".</li>
    <li>Para borrar las cookies y conservar el historial, ve a Configuración > Safari > Avanzado > Datos de sitios web y, luego, toca Eliminar todos los datos.</li>
   </ol>
  </div>
 </div>
</section>