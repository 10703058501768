import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/auto/services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  formContacto: FormGroup;
  errorMessage:any;
  loading:boolean=false;
  submitted = false;

  emailstring:string= "mailto:info@asegurate.com?Subject=Contacto desde asegurate.com.do";
  callUs:string='tel:+1-809-620-2524';

  whatsUs:string='https://api.whatsapp.com/send?phone=18096202524&text=Hola!, necesito tu ayuda con el proceso para asegurarme en ASEGURATE.COM.DO';
  

  constructor(
    private http:HttpClient,
    private formBuilder: FormBuilder,
    private dataService: DataService
    ) { }

  ngOnInit(): void {
    this.dataService.Interaccion(0,"El usuario vistito el apartado de Contacto", 0)
    this.formContacto = this.formBuilder.group({
      comentarioCon:  ['', Validators.required],
      nombreCon: ['', [Validators.required, Validators.pattern('^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF_ ]+$')]],
      correoCon: ['', [Validators.required, Validators.email]],  
    });
  }

  get f() { return this.formContacto.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.formContacto.invalid) {
        return;
    }
    Swal.fire({
      title: '¡Casi listo!',
      text: 'Por favor, presione OK para envíar el correo.',
      showCancelButton: true,
      confirmButtonColor: '#FF6720',
      cancelButtonColor: '#8a8a8a',
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
         this.loading=true;
       this.http.post<any>('https://api.segurnet.com.do/api/Contacto?Mensaje='+this.formContacto.value.comentarioCon+'&Correo='+this.formContacto.value.correoCon+'&Nombre='+this.formContacto.value.nombreCon, null).subscribe({
          next: data => {
            this.loading=false;
              Swal.fire({
                icon: 'success',
                title: 'Sus correo ha sido envíado correctamente.',
                timer: 6000
              }).then((result)=>{
                if (result.dismiss === Swal.DismissReason.timer) {window.open('contacto', '_self');}
                if (result.isConfirmed){window.open('contacto', '_self');}
              });
          }, error: error => {
           // console.log(error);
            this.loading=false;
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo salio mal, por favor vuelva a intentarlo'
            })
              this.errorMessage = error.message;
          }
        })
      }else if (result.isDenied) {
        return;
      }
    })
}

//Go wassap
goWhatapp(){
  window.open(this.whatsUs, "_blank");
 }



}
