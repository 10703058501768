<header *ngIf='showMenu' class='container px-0 px-md-0' id='mainMenu'>
  <nav class='navbar navbar-expand-lg navbar-light mb-4 container-fluid' id='menu'>
    <a id="logoAseg" class='navbar-brand navLogo' routerLink=''><img src='assets/img/logo.svg'
        alt='Logo de Asegurate'></a>
    <button class='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#hamMovil'
      aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
      <span class='navbar-toggler-icon'></span>
    </button>
    <div class='collapse navbar-collapse d-lg-flex' id='hamMovil'>

      <ul class='navbar-nav mb-2 mb-lg-0'>
        <li class='nav-item' routerLink='' routerLinkActive='actualPag' [routerLinkActiveOptions]="{ exact: true }"
          tabindex="-1">
          <a class='nav-link' href=''><!-- <i class="bi bi-house"></i>--> Inicio</a>

        </li>
        <li class='nav-item' routerLink='preguntas-frecuentes' routerLinkActive='actualPag' tabindex="-1">
          <a class='nav-link' href='preguntas-frecuentes'><!-- <i class="bi bi-question-circle"></i>--> Preguntas y
            Respuestas</a>
        </li>
        <li class='nav-item' routerLink='contacto' routerLinkActive='actualPag' tabindex="-1">
          <a class='nav-link' href='contacto'>Contacto</a>
        </li>

        <li style="min-width:200px;">
          <div class="login" *ngIf="bodyJsonLogin == null">
            <a class='btn' id='Acceder' (click)="openLoginModal()"><i class="bi bi-person toggle-icon"></i> </a>
          </div>
    
    
          <div class="login" *ngIf="bodyJsonLogin != null">
            <a class="btn" (click)="toggleDropdown()">
              <i class="bi bi-person-circle"></i> {{bodyJsonLogin.response.user._nombre}}
              <i class="bi bi-caret-down-fill"></i>
            </a>
    
            <div *ngIf="isDropdownOpen" class="drop-menu">
              <!-- <a class="drop-item" href="https://servicios.segurnet.com.do/">Ir a Servicios</a> -->
              <a class="drop-item" href="https://servicios.segurnet.com.do/" target="_blank"><i
                  class="bi bi-person-fill-gear"></i> <span>Ir a Servicios</span></a>
              <a class="drop-item" (click)="logout()"><i class="bi bi-box-arrow-right"></i> <span>Cerrar sesión</span></a>
            </div>
          </div>
        </li>
      </ul>


      <!-- <div class="login" *ngIf="bodyJsonLogin == null">
          <a class='btn' id='Acceder'(click)="openLoginModal()"><i class="bi bi-person toggle-icon"></i> </a>
      </div>

      
      <div class="login" *ngIf="bodyJsonLogin != null">
        <a class="btn" (click)="toggleDropdown()">
          <i class="bi bi-person-circle"></i> {{bodyJsonLogin.response.user._nombre}}
          <i class="bi bi-caret-down-fill"></i>
        </a>

        <div *ngIf="isDropdownOpen" class="drop-menu">
          <a class="drop-item" href="https://servicios.segurnet.com.do/login" target="_blank"><i class="bi bi-person-fill-gear"></i> <span>Ir a Servicios</span></a>
          <a class="drop-item" (click)="logout()"><i class="bi bi-box-arrow-right"></i>  <span>Cerrar sesión</span></a>
        </div>
      </div> -->


      

    </div>







  </nav>
</header>
<app-login></app-login>
<main>
  <router-outlet></router-outlet>
</main>

<footer *ngIf='showMenu' class='pt-2 pt-lg-4'>
  <div class='mt-4 mb-5'>
    <img class='center' src='assets/img/poweredBy.svg' alt='logo Segurnet'>
  </div>
  <div>
    <div class='p-3'>
      <nav class='nav'>
        <!-- <span  routerLink='' routerLinkActive='actPagFoo' [routerLinkActiveOptions]="{ exact: true }"  tabindex="-1"> -->
        <a class='nav-link px-2 px-md-4' href=''>Inicio</a>
        <!-- </span> -->
        <!-- <span routerLink='preguntas-frecuentes' routerLinkActive='actPagFoo'  tabindex="-1"> -->
        <a class='nav-link px-2 px-md-4' href='preguntas-frecuentes'>Preguntas y Respuestas</a>
        <!-- </span> -->
        <!-- <span routerLink='contacto' routerLinkActive='actPagFoo'  tabindex="-1"> -->
        <a class='nav-link px-2 px-md-4' href='contacto'>Contacto</a>
        <!-- </span> -->
        <div class='ms-auto socialMediaCtn'>
          <a href='{{whatsUs}}' class="socialIconFooter" target='_blank' rel="nofollow noopener noreferrer">
            <i class="fi fi-brands-whatsapp mx-1 socialIconFooter"></i>
          </a>
          <a href='https://fb.me/asegurate.do' class="socialIconFooter" target='_blank'
            rel="nofollow noopener noreferrer">
            <i class="fi fi-brands-facebook mx-1 socialIconFooter"></i>
          </a>
          <a href='https://www.instagram.com/asegurate.do/' target='_blank' class="socialIconFooter"
            rel="nofollow noopener noreferrer">
            <i class="fi fi-brands-instagram mx-1 socialIconFooter"></i>
          </a>
        </div>
      </nav>
    </div>

    <div class='my-5'>
      <img class='center' src='../assets/img/whiteLogo.svg' alt='Logo Asegurate'>
    </div>

    <div class='row mx-0 px-0' id='subFooter'>
      <div class='col-6'>
        <p class='text-start mb-0 p-2 pe-4'>
          <a href='terminos-y-condiciones' target='_blank'>Términos y condiciones</a>&#160;&#160; | &#160;&#160;<a
            href='politicas-de-cookies' target='_blank'>Políticas de cookies</a>
        </p>
      </div>
      <div class='col-6'>
        <p class='text-end mb-0 p-2 pe-4'>© 2024 - Asegúrate.do</p>
      </div>
    </div>

  </div>
</footer>