import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit} from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import{myValidations} from '../utils/my-validations';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-inspeccion',
  templateUrl: './inspeccion.component.html',
  styleUrls: ['./inspeccion.component.css']
})
export class InspeccionComponent implements OnInit {
  //show or hide desktop
  isDesktop:boolean=true;
  //EM ID
  emId:string;
  ConoceCliente:any={} //Conoce tu cliente
   jsonOb: JSON;

   //Sguardar data Conoce tu cliente
   btnShowSaveData:boolean=true;

   loading:boolean=false; //loading

  formConoceClit:FormGroup;
  saveDataCC = false;
  formInspeccion:FormGroup;
  submitted = false;
  openModal:string='#exampleModal';

  //input otros
  otroActivDesc:boolean=false;
  otroIngPromMen:boolean=false;
  recuPu:boolean=false;
  podPub:boolean=false;
  inflPub:boolean=false;
  AfirmToOther:boolean=false;

  aActFinan:string[]=[]; //Save the data of finalcial activities
  getEmpty:any=null;
  allData:any={}; //Object for the data "Conoce tu cliente"
  errorMessage:string // mensaje error

  //Declarar la variables para las fotos
  FotCedula:string;
  FotLicencia:string;
  FotMatricula:string;
  FotPartFrontal:string;
  FotLatDerecha:string;
  FotLatIzquierda :string;
  FotPartSuper:string;
  FotInteriorBaul:string;
  FotChasis:string;
  FotOdometro:string;
  FotIntDelantero:string;
  FotIntTrasero:string;
  FotTrasera:string;
  FotMotor:string;
  FotCedTrasera:string;
  FotOtro:string;
  FotRepuesto:string;

  //mensaje en caso de no permitir la inspeccion
  mensaje:string;
  noPermitido:boolean=false;
 
  
  constructor(
    private http:HttpClient,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe(params =>{
      this.emId=params.emiId;
    })
   }

   
  ngOnInit(){
    this.detectAdvise(); 
    if(!this.isDesktop){
      //validar el EmID
     // this.checkEmID(this.emId);
      setTimeout(
        () => this.openModalInsp(), 2000
      )
    }    
    this.formConoceClit = this.formBuilder.group({      
      InfActFinancieraDescr: [null,[Validators.pattern('^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF_ ]+$'), myValidations.whitespace]],
    /* feVencimi: ['', [Validators.required]],
      inpSex:['', Validators.required],
      fechaNac: ['', [Validators.required]],
      cuidadNac: ['',[Validators.required, Validators.pattern('^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF_ ]+$'), myValidations.whitespace]],
      nacionalidad: ['',[Validators.required, Validators.pattern('^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF_ ]+$'), myValidations.whitespace]],*/
      pronvinNac: ['',[Validators.required, Validators.pattern('^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF_ ]+$'), myValidations.whitespace]],
      cuidadRes: ['',[Validators.required, Validators.pattern('^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF_ ]+$'), myValidations.whitespace]],
      direccion: ['',[Validators.required, myValidations.whitespace]],
      InfActEconomica: ['', Validators.requiredTrue],
      InfActFinanciera: ['', Validators.required],
      InfActEcoOtro:null,
      otrActEconomica:null,
      InfRecursosP: ['', Validators.required],
      InfRecursosPDescripcion: null,
      InfInfluenciaPub: ['', Validators.required],
      InfPoderPublicoDescripcion: null,
      InfPoderPublico: ['', Validators.required],
      InfInfluenciaPubDescripcion: null,
      InfAfirmativaCon:['', Validators.required],
      InfAfirmativaConDescripcion: null
    })

    this.formInspeccion = this.formBuilder.group({
      cantPasajeros: ['',[Validators.required, Validators.pattern('[0-9]*'), myValidations.whitespace]],
      Colindros:['',[Validators.required, Validators.pattern('[0-9]*'), myValidations.whitespace]],
      Odometro: ['',[Validators.required, Validators.pattern('[0-9]*'), myValidations.whitespace]],
      UniOdom: ['', Validators.required],
      imgPartFront: ['', Validators.required],
      imgPartSup: ['', Validators.required],
      imgLatDerecho:['', Validators.required],
      imgPartTrasera: ['', Validators.required],
      imgIntBaul: ['', Validators.required],
      imgLatIzq: ['',Validators.required],
      imgChasis: ['', Validators.required],
      imgOdometro: ['', Validators.required],
      imgIntTablero: ['',Validators.required],
      imgAsiIntTrasero: ['',Validators.required],
      imgMotor: ['',Validators.required],
      imgRepuestaGL:[''],
      imgCedula: ['', Validators.required],
      imgCedTrasera: ['', Validators.required],
      imgMatricula: ['', Validators.required],
      imgLicencia: ['', Validators.required],
      imgOtroDocumento:''
    }) 
  }

 
  //Detect Advise
  detectAdvise(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      this.isDesktop=false;
      //this.openModalInsp();
    }else{
      // false for not mobile device
      this.isDesktop=true;
    }
  }

  // Validate EMID for inspection
  checkEmID(id:any){
    this.http.get(environment.baseURL+'/cotizador/Inspeccionar').subscribe({
      next: data => {
       // this.loading=false;
          if(data==''){

          }
      },
      error: error => {
       // this.loading=false;
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: 'Algo salio mal, por favor vuelva a intentarlo.<br>'+
          '<br>Si el error persiste por favor comuniquese con nosotros al 809-620-2524 o '+
          '<a href="https://api.whatsapp.com/send?phone=18096202524&text=Hola, ha ocurrido un error al entrar para realizar mi inspeccion en ASEGURATE.COM.DO. Necesito su asistencia." target="_blank">escríbenos a WhatsApp</a>'
        })
          this.errorMessage = error.message;
          alert(error.message);
      }
    })
  }
        /********************
         * CONOCE TU CLIENTE
        ********************* */

  //Active the validation
  get v() { return this.formConoceClit.controls; }  
    
  // Save data 
  submitConoClient() {
    this.saveDataCC = true; 
      //Input validation
    if (this.formConoceClit.invalid) { 
      return;
    }
    else{      
      this.saveDataClient();
    }
  }
  // Delete checkbox info from the array isnt check
    removeItem(array, item){
      for(var i in array){
          if(array[i]==item){
              array.splice(i,1);
              break;
          }
      }
    };

    //Save the checklist data from "actividadFinanciera"
  miActividades(e:any){
    if(e.checked){
      this.aActFinan.push(e.value);
    }else{
      this.removeItem(this.aActFinan, e.value);
    }
  }

  //Activate input for aditional information
  actiInput(e:any){  //Financial activities
    if(e.checked){
      this.otroActivDesc=true;
      this.validateInput(true,'otrActEconomica');
    }else{
      this.otroActivDesc=false;
      this.validateInput(false,'otrActEconomica');
      this.formConoceClit.controls['otrActEconomica'].reset(this.getEmpty);
    }
  }
  seeOtPromMen(elem){ //Financial information
    if(elem.value == 'Otros ingresos promedio mensual RD$'){
      this.otroIngPromMen=true;
      this.validateInput(true,'InfActEcoOtro');
    } else{
      this.otroIngPromMen=false;
      this.validateInput(false,'InfActEcoOtro');
      this.formConoceClit.controls['InfActEcoOtro'].reset(this.getEmpty);
    }
  }  
  recurPublic(elem){  //Public Recurses
    if(elem.value == 'Si'){
      this.recuPu=true;
      this.validateInput(true,'InfRecursosPDescripcion');
    } else{
      this.recuPu=false;
      this.validateInput(false,'InfRecursosPDescripcion');
      this.formConoceClit.controls['InfRecursosPDescripcion'].reset(this.getEmpty);
    }
  }
  poderPub(elem){ //Public power
    if(elem.value == 'Si'){
      this.podPub=true;
      this.validateInput(true,'InfPoderPublicoDescripcion');
    } else{
      this.podPub=false;
      this.validateInput(false,'InfPoderPublicoDescripcion');
      this.formConoceClit.controls['InfPoderPublicoDescripcion'].reset(this.getEmpty);
    }
  }
  influenciaPu(elem){  //Public influencies
    if(elem.value == 'Si'){
      this.inflPub=true;
      this.validateInput(true,'InfInfluenciaPubDescripcion');
    } else{
      this.inflPub=false;
      this.validateInput(false,'InfInfluenciaPubDescripcion');
      this.formConoceClit.controls['InfInfluenciaPubDescripcion'].reset(this.getEmpty);
    }
  }
  afirmatOther(elem){ //This q is afirmetive for other
    if(elem.value == 'Si'){
      this.AfirmToOther=true;
      this.validateInput(true,'InfAfirmativaConDescripcion');
    } else{
      this.AfirmToOther=false;
      this.validateInput(false,'InfAfirmativaConDescripcion');
      this.formConoceClit.controls['InfAfirmativaConDescripcion'].reset(this.getEmpty);
    }
  }

  //set conditional validation for the input depends if afirmative
  validateInput(status, elemento){
    if(status){
      this.formConoceClit.controls[elemento].setValidators([Validators.required, myValidations.whitespace]);
        this.formConoceClit.controls[elemento].updateValueAndValidity();
    }else{
      this.formConoceClit.controls[elemento].setValidators(null);
        this.formConoceClit.controls[elemento].updateValueAndValidity();
    }
  }

 //Open Modal onInit
  openModalInsp(){
    let modalbtn:any = document.getElementById('modalConoceTuCliente');
    modalbtn.click();
  }

  //Detect if the Modal is being closed
  closeModalInsp(status:boolean=true){
    let close:any = document.getElementById('clsModBtn');
    if(status){
      if(confirm('Atención. Debe completar el formulario conoce tu cliente para evitar retraso en proceso de esta solicitud de póliza de seguros.')){
      close.click();
    }
    } else{
        close.click();
    }
    
  }

  //Save the form data in the object "ConoceCLiente"
  saveDataClient(){
    let otroInfoFinanciera;
    if (this.otroIngPromMen){
      otroInfoFinanciera = this.formConoceClit.value.InfActEcoOtro;
    } else{
      otroInfoFinanciera = this.formConoceClit.value.InfActFinanciera;
    }  
    
    if (this.otroActivDesc){this.aActFinan.push(this.formConoceClit.value.otrActEconomica)}

    let activFinan = JSON.stringify(this.aActFinan);

    this.ConoceCliente ={
      InfActEconomica: activFinan,
      InfActFinanciera: otroInfoFinanciera,
      InfActFinancieraDescr:this.formConoceClit.value.InfActFinancieraDescr,
      InfRecursosP:this.formConoceClit.value.InfRecursosP,
      InfRecursosPDescripcion:this.formConoceClit.value.InfRecursosPDescripcion,
      InfPoderPublico:this.formConoceClit.value.InfPoderPublico,
      InfPoderPublicoDescripcion:this.formConoceClit.value.InfPoderPublicoDescripcion,
      InfInfluenciaPub:this.formConoceClit.value.InfInfluenciaPub,
      InfInfluenciaPubDescripcion:this.formConoceClit.value.InfInfluenciaPubDescripcion,
      InfAfirmativaCon:this.formConoceClit.value.InfAfirmativaCon,
      InfAfirmativaConDescripcion:this.formConoceClit.value.InfAfirmativaConDescripcion,
      InfSolSeguro: 'Generales'
    }
    this.closeModalInsp(false); //Cerrar modal
  }
//Able "save data" from Conoce tu Cliente form 
aceptoDecl(elem:any){
  if(elem.checked){
    this.btnShowSaveData=false;
  } else{
    this.btnShowSaveData=true;
  }
};

//Go wassap
goWhatappInpe(){
  window.open('https://api.whatsapp.com/send?phone=18096202524&text=Hola, me ha salido en siguiente mensaje: "'+this.mensaje+'" en la pagina de ASEGURATE.COM.DO y necesito su asistencia.', "_blank");
 }


/********************
  * INSPECCION
********************* */

  get f() { return this.formInspeccion.controls; }

    /* Al enviar el formulario de inspeccion*/
    onSubmit() {
      this.submitted = true; 
        // Si es invalido parar
      if (this.formInspeccion.invalid) {
        return;
      }
      else{
       /* const httpOptions = {
          headers: new HttpHeaders({  "Content-Type": "application/x-www-form-urlencoded" })
        };*/
        let headers = new HttpHeaders()
        headers=headers.set('content-type','application/x-www-form-urlencoded');
        this.loading=true;
        this.allData={
            'emiId':this.emId,
            'CantPasajeros':this.formInspeccion.value.cantPasajeros,
            'Colindros':this.formInspeccion.value.Colindros,
            'Odometro':this.formInspeccion.value.Odometro,
            'UniOdom':this.formInspeccion.value.UniOdom,
            'FotCedula':this.FotCedula,
            'FotLicencia':this.FotLicencia,
            'FotMatricula':this.FotMatricula,
            'FotPartFrontal':this.FotPartFrontal,
            'FotLatDerecha':this.FotLatDerecha,
            'FotLatIzquierda' :this.FotLatIzquierda,
            'FotPartSuper':this.FotPartSuper,
            'FotInteriorBaul':this.FotInteriorBaul,
            'FotChasis':this.FotChasis,
            'FotOdometro':this.FotOdometro,
            'FotIntDelantero':this.FotIntDelantero,
            'FotIntTrasero':this.FotIntTrasero,
            'FotTrasera':this.FotTrasera,
            'FotMotor':this.FotMotor,
            'FotCedTrasera':this.FotCedTrasera,
            'FotOtro':this.FotOtro,
            'FotRepuesto':this.FotRepuesto,
            'ConoceCliente': this.ConoceCliente
        };
        this.jsonOb=<JSON>this.allData;
        this.http.post<any>(environment.baseURL+'/cotizador/Inspeccionar', this.jsonOb).subscribe({
          next: data => {
            this.loading=false;
              Swal.fire({
                icon: 'success',
                title: 'Sus datos han sido envíados correctamente.',
                allowOutsideClick: false,
                timer: 6000
              }).then((result)=>{
                if (result.dismiss === Swal.DismissReason.timer) {
                  sessionStorage.setItem('raload', 'true');
                  this.router.navigate(['']);
                }
                if (result.isConfirmed){
                  sessionStorage.setItem('raload', 'true');
                  this.router.navigate(['']);
              }
            });
          },
          error: error => {
            //console.log(error);
            this.loading=false;
            Swal.fire({
              icon: 'error',
              title: 'Error',
              html: 'Algo salio mal, por favor vuelva a intentarlo.<br>'+
              '<br>Si el error persiste por favor comuniquese con nosotros al 809-620-2524 o '+
              '<a href="https://api.whatsapp.com/send?phone=18096202524&text=Hola, ha ocurrido un error en mi proceso de inspección en ASEGURATE.COM.DO y necesito su asistencia." target="_blank">escríbenos a WhatsApp</a>'
            })
              this.errorMessage = error.message;
              alert(error.message);
          }
        });
      }
    }


    //Generar el Base64 de las imagenes

    getBaseCedula(img){ //Cedula frontal
      let output:any = document.getElementById('FotCedula');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotCedula =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotCedula ='';
      }
    }
    getBasecedTrase(img){ //Cedula trasera
      let output:any = document.getElementById('FotCedTrasera');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotCedTrasera =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotCedTrasera ='';
      }
    }
    getBaseLince(img){//Foto licencia
      let output:any = document.getElementById('FotLicencia');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotLicencia =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotLicencia ='';
      }
    }
    getBaseMatri(img){//Foto Matricula
      let output:any = document.getElementById('FotMatricula');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotMatricula =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotMatricula ='';
      }
    }
    getBaseFotOtro(img){//Otro Documento
      let output:any = document.getElementById('FotOtro');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotOtro =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotOtro ='';
      }
    }
    getBaseFrontal(img){//Foto parte frontal
      let output:any = document.getElementById('FotPartFrontal');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotPartFrontal =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotPartFrontal ='';
      }
    }
    getBaseLatDer(img){//Foto lateral derecha
      let output:any = document.getElementById('FotLatDerecha');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotLatDerecha =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotLatDerecha ='';
      }
    }
    getBaseLatIzq(img){//Foto lateral izquierdo
      let output:any = document.getElementById('FotLatIzquierda');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotLatIzquierda =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotLatIzquierda ='';
      }
    }
    getBaseSupe(img){//Foto PArte Superior
      let output:any = document.getElementById('FotPartSuper');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotPartSuper =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotPartSuper ='';
      }
    }
    getBaseIntBaul(img){//Foto interio Baul
      let output:any = document.getElementById('FotInteriorBaul');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotInteriorBaul =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotInteriorBaul ='';
      }
    }
    getBaseChasis(img){//Foto Chasis
      let output:any = document.getElementById('FotChasis');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotChasis =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotChasis ='';
      }
    }
    getBaseOdo(img){//Foto Odometro
      let output:any = document.getElementById('FotOdometro');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotOdometro =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotOdometro ='';
      }
    }
    getBaseIntDel(img){//Foto interior delantero
      let output:any = document.getElementById('FotIntDelantero');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotIntDelantero =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotIntDelantero ='';
      }
    }
    getBaseInTra(img){//Foto interio trasero
      let output:any = document.getElementById('FotIntTrasero');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotIntTrasero =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotIntTrasero ='';
      }
    }
    getBaseTra(img){//Foto trasera
      let output:any = document.getElementById('FotTrasera');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotTrasera =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotTrasera ='';
      }
    }
    getBaseMotor(img){//Foto del Motor
      let output:any = document.getElementById('FotMotor');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotMotor =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotMotor ='';
      }
    }
    getBaseGato(img){//Foto del Gato/Llave
      let output:any = document.getElementById('FotRepuesto');
      if (img.files && img.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.FotRepuesto =e.target.result.split(';base64,')[1];
          output.src = reader.result;
        }
        reader.readAsDataURL(img.files[0]);
      } else{
        output.src = 'assets/img/upload.svg'; // Resetar la imagen
        this.FotRepuesto ='';
      }
    }

}
