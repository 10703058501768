<!-- Gif loading  -->
<div style='background-color:rgba(238, 238, 238, 0.767); width:100%; height: 100%; z-index:9999999!important;position:fixed; top:0;  left:0; display: flex; flex-direction:column; justify-content: center;  align-items: center;' id='progress' *ngIf='loading'>
	<h3 class='regBold text-center'>¡Enviando datos!<br><br>Estamos los enviado los datos de tu inspección.</h3>
	<img src="assets/img/gifAseg.gif" width='250px'>
  <h5 class='regBold text-center px-2'>Este proceso puede tomar unos minutos, por favor espere.</h5>
</div>

<!-- Mensaje desktop -->
<section class='container my-5 px-0' *ngIf='isDesktop' style='min-height:20em;'>
  <div class='row mt-5 d-flex justify-content-center'>
    <div class='col-11 text-center my-5'>
      <h4 class='regBold'>Para acceder a nuestro formulario de inspección debe hacerlo a través de un dispositivo móvil. <br>Disculpe los inconvenientes.</h4>
    </div>
  </div>
</section>

<!-- Mensaje inspeccion no permitda -->
<section class='container my-5 px-0' *ngIf='noPermitido' >
  <div class='row mt-5 d-flex justify-content-center'>
    <div class='col-11 text-center my-5'>
      <h4 class='regBold'>{{mensaje}}</h4>
      <p>Si considera que esto puede ser un error por favor comuniquese con nosotros al <span class='regBold'>809-620-2524</span> o <a class='linkAseg regBold' (click)='goWhatappInpe()'>puedes escríbenos a WhatsApp</a> con gusto te atendemos.</p>
      <img class='mt-5' src='assets/img/fullLogo.svg' alt='Logo Asegurate' id='logoFullText'> <br>
      <a href='' class='mt-5' id='linkGoHom'>Ir a la pagina principal asegurate.com.do</a>
    </div>
  </div>
</section>


<section class='container mb-5 mt-4 mt-md-3 px-0' id='cntHeaderInspe' *ngIf='!isDesktop'>
 <div>
   <img class='center' src='assets/img/logo.svg' alt='Logo Asegurate'> 
  <h2 class='regBold text-center mt-4'>Formulario de inspección vehículo</h2>
 </div>

 <!-- Modal -->
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#conoceTuCliente" id='modalConoceTuCliente'><img src='assets/img/fclient.svg' alt='Imagen formulario conoce tu cliente'></button>

<!-- Modal - Formulario conoce tu cliente -->
<div class="modal fade" id="conoceTuCliente" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title regBold" id="exampleModalLabel">Queremos saber un poco más de ti</p>
        <button type="button" class="btn-close" aria-label="Close" (click)='closeModalInsp()'></button>
      </div>
      <div class="modal-body">
        <form [formGroup]='formConoceClit'>

          <!-- Persona fisica -->
          <div>
            <div>
              <p class='mb-1 text-uppercase'>Información persona física</p>
            </div>
              
            <div class='mb-2'>
              <!-- Fecha de vencimiento -->
              <!-- <div class='col-md-6 mt-2' id='cntFvenci'>
                <label for='feVencimi' class='form-label mb-0'>Fecha de vencimiento</label><br id='brFechNac'><small>Del documento de idenficación proporcionado anteriormente.</small>
                <input type='date' class='form-control' id='feVencimi' placeholder='dd/mm/aaaa' name='feVencimi' formControlName='feVencimi' [ngClass]='{"is-invalid": saveDataCC && v.feVencimi.errors}'>
                
                <div *ngIf='saveDataCC && v.feVencimi.errors' class='invalid-feedback'>
                  <div *ngIf='v.feVencimi.errors.required'>Por favor, coloque la fecha de vencimiento de su documento de identidad.</div>
                </div>
              </div> -->

               <!-- Sexo -->
              <!-- <div>
                <label for='sexo' class='col-md-6 col-lg-4 mt-2'>Sexo: &nbsp;</label>
                <div class='form-check form-check-inline'>
                  <input formControlName='inpSex' class='form-check-input' type='radio' name='inpSex' id='sexoF' value='F' [ngClass]='{ "is-invalid": saveDataCC && v.inpSex.errors}'>
                  <label class='form-check-label' for='sexoF'>F</label>
                </div>
                <div class='form-check form-check-inline'>
                  <input formControlName='inpSex' class='form-check-input' type='radio' name='inpSex' id='sexoM' value='M' [ngClass]='{ "is-invalid": saveDataCC && v.inpSex.errors}'>
                  <label class='form-check-label' for='sexoM'>M</label>
                </div>
                <div *ngIf='saveDataCC && v.inpSex.errors?.required' class='invalidText'>Seleccione el sexo</div>                
              </div> -->

              <!-- fecha de nacimiento -->
              <!-- <div class='col-md-6 mt-2'>
                <label>Fecha de nacimiento</label>
                <input type='date' formControlName='fechaNac' class='form-control' [ngClass]='{"is-invalid": saveDataCC && v.fechaNac.errors }'/>
                <div *ngIf='saveDataCC && v.fechaNac.errors' class='invalid-feedback'>
                    <div *ngIf='v.fechaNac.errors.required'>Por favor, coloque la fecha de nacimiento.</div>
                </div>
              </div> -->

              <!-- Cuidad de nacimiento -->
              <!-- <div class='col-md-6 mt-2' id='cntFvenci'>
                <label for='cuidadNac' class='form-label mb-0'>Cuidad de nacimiento:</label>
                <input type='text' class='form-control' id='cuidadNac' placeholder='Cuidad' name='cuidadNac' formControlName='cuidadNac' [ngClass]='{"is-invalid": saveDataCC && v.cuidadNac.errors}'>
                
                <div *ngIf='saveDataCC && v.cuidadNac.errors' class='invalid-feedback'>
                  <div *ngIf='v.cuidadNac.errors.required || v.cuidadNac.errors.whitespace'>Por favor, coloque la cuidad de nacimiento.</div>
                  <div *ngIf='v.cuidadNac.errors.pattern'>La cuidad de nacimiento debe contener sólo texto. [A-Z]</div>
                </div>
              </div> -->

              
              <!-- Provincia de Nacimiento -->
              <div class='col-md-6 mt-2' id='cntFvenci'>
                <label for='pronvinNac' class='form-label mb-0'>Provincia de nacimiento:</label>
                <input type='text' class='form-control' id='pronvinNac' placeholder='Provincia' name='pronvinNac' formControlName='pronvinNac' [ngClass]='{"is-invalid": saveDataCC && v.pronvinNac.errors}'>
                
                <div *ngIf='saveDataCC && v.pronvinNac.errors' class='invalid-feedback'>
                  <div *ngIf='v.pronvinNac.errors.required || v.pronvinNac.errors.whitespace'>Por favor, coloque la provincia de nacimiento.</div>
                  <div *ngIf='v.pronvinNac.errors.pattern'>La provincia de nacimiento debe contener sólo texto. [A-Z]</div>
                </div>
              </div>
    
             <!-- Nacionalidad -->
              <!-- <div class='col-md-6 mt-2' id='cntFvenci'>
                <label for='nacionalidad' class='form-label mb-0'>Nacionalidad:</label>
                <input type='text' class='form-control' id='Nacionalidad' placeholder='Nacionalidad' name='nacionalidad' formControlName='nacionalidad' [ngClass]='{"is-invalid": saveDataCC && v.nacionalidad.errors}'>
                
                <div *ngIf='saveDataCC && v.nacionalidad.errors' class='invalid-feedback'>
                  <div *ngIf='v.nacionalidad.errors.required || v.nacionalidad.errors.whitespace'>Por favor, coloque la nacionalidad.</div>
                  <div *ngIf='v.nacionalidad.errors.pattern'>La nacionalidad debe contener sólo texto. [A-Z]</div>
                </div>
              </div> -->

            </div>
    
          </div>

           <!-- Domicilio -->
          <div class='mt-2'>
            <div>
              <p class='mb-1 text-uppercase'>Domicilio</p>
            </div>
              
            <div class='mb-2 row'>
              <!-- Cuidad de residencia -->
              <div class='col-md-6 mt-2' id='cntFvenci'>
                <label for='cuidadRes' class='form-label mb-0'>Cuidad de residencia:</label>
                <input type='text' class='form-control' id='cuidadRes' placeholder='Cuidad de residencia' name='cuidadRes' formControlName='cuidadRes' [ngClass]='{"is-invalid": saveDataCC && v.cuidadRes.errors}'>
                
                <div *ngIf='saveDataCC && v.cuidadRes.errors' class='invalid-feedback'>
                  <div *ngIf='v.cuidadRes.errors.required'>Por favor, coloque la cuidad de residencia.</div>
                  <div *ngIf='v.cuidadRes.errors.whitespace'>Por favor, elimine el espacio en blanco.</div>
                  <div *ngIf='v.cuidadRes.errors.pattern'>La cuidad de residencia debe contener sólo texto. [A-Z]</div>
                </div>
              </div>
              
              <!-- Direccion -->
              <div class='col-md-6 mt-2' id='cntFvenci'>
                <label for='direccion' class='form-label mb-0'>Dirección:</label>
                <input type='text' class='form-control' id='direccion' placeholder='Dirección' name='direccion' formControlName='direccion' [ngClass]='{"is-invalid": saveDataCC && v.direccion.errors}'>
                
                <div *ngIf='saveDataCC && v.direccion.errors' class='invalid-feedback'>
                  <div *ngIf='v.direccion.errors.required || v.direccion.errors.whitespace'>Por favor, coloque la dirección.</div>
                </div>
              </div>

            </div>
          </div>

          
          <!-- Actividad financiera -->
          <div class='mt-2'>
            <div>
              <p class='mb-1 text-uppercase'>Actividad financiera</p>
            </div>

            <div class='mb-2'>
            <!-- Actividad -->
              <div>
                <label for='activiEcon' class='mt-2'>Actividad: &nbsp;</label><br>
                <div *ngIf='saveDataCC && v.InfActEconomica.errors?.required' class='invalidText'>Seleccione la actividad</div> 
                <div class='form-check form-check-inline'>
                  <input formControlName='InfActEconomica' class='form-check-input' type='checkbox'  id='EmpAsa' value='Empleado/Asalariado'  [ngClass]='{ "is-invalid": saveDataCC && v.InfActEconomica.errors}' (change)='miActividades($event.target)'>
                  <label class="form-check-label" for="EmpAsa">Empleado/Asalariado</label>
                </div>
                <div class="form-check form-check-inline">
                  <input formControlName='InfActEconomica' class='form-check-input' type='checkbox'  id='propSoc' value='Propietario/Socio' [ngClass]='{ "is-invalid": saveDataCC && v.InfActEconomica.errors}' (change)='miActividades($event.target)'>
                  <label class="form-check-label" for="propSoc">Propietario/Socio</label>
                </div>
                <div class="form-check form-check-inline">
                  <input formControlName='InfActEconomica' class='form-check-input' type='checkbox'  id='jubPens' value='Jubilado/Pensionado' [ngClass]='{ "is-invalid": saveDataCC && v.InfActEconomica.errors}' (change)='miActividades($event.target)'>
                  <label class="form-check-label" for="jubPens">Jubilado/Pensionado</label>
                </div>
                <div class="form-check form-check-inline">
                  <input formControlName='InfActEconomica' class='form-check-input' type='checkbox'  id='invPrest' value='Inversionista/Prestamista' [ngClass]='{ "is-invalid": saveDataCC && v.InfActEconomica.errors}' (change)='miActividades($event.target)'>
                  <label class="form-check-label" for="invPrest">Inversionista/Prestamista</label>
                </div>
                <div class="form-check form-check-inline">
                  <input formControlName='InfActEconomica' class='form-check-input' type='checkbox'  id='independient' value='Independiente' [ngClass]='{ "is-invalid": saveDataCC && v.InfActEconomica.errors}' (change)='miActividades($event.target)'>
                  <label class="form-check-label" for="independient">Independiente</label>
                </div>
                <div class="form-check form-check-inline">
                  <input formControlName='InfActEconomica' class='form-check-input' type='checkbox'  id='estudiant' value='Estudiante'[ngClass]='{ "is-invalid": saveDataCC && v.InfActEconomica.errors}' (change)='miActividades($event.target)'>
                  <label class="form-check-label" for="estudiant">Estudiante</label>
                </div>
                <div class="form-check form-check-inline">
                  <input formControlName='InfActEconomica' class='form-check-input' type='checkbox'  id='amaCasa' value='Ama de casa' [ngClass]='{ "is-invalid": saveDataCC && v.InfActEconomica.errors}' (change)='miActividades($event.target)'>
                  <label class="form-check-label" for="amaCasa">Ama de casa</label>
                </div>
                <div class="form-check form-check-inline">
                  <input formControlName='InfActEconomica' class='form-check-input' type='checkbox'  id='otro' value='otro' [ngClass]='{ "is-invalid": saveDataCC && v.InfActEconomica.errors}' (change)='actiInput($event.target)'>
                  <label class="form-check-label" for="otro">Otro ¿cuál?</label>
                  <input type='text' class='form-control' placeholder='Describa aquí la actividad' formControlName='otrActEconomica' *ngIf='otroActivDesc' [ngClass]='{"is-invalid": saveDataCC && v.otrActEconomica.errors}'>                
                <div *ngIf='saveDataCC && v.otrActEconomica.errors' class='invalid-feedback'>
                  <div *ngIf='v.otrActEconomica.errors.required || v.otrActEconomica.errors.whitespace'>Por favor, coloque la actividad.</div>
                  <div *ngIf='v.otrActEconomica.errors.pattern'>La actividad debe contener sólo texto. [A-Z]</div>
                </div>
                </div>
                
              </div>
            </div>
          </div>
          
          <!-- Informacion financiera -->
          <div class='mt-2'>
            <div>
              <p class='mb-1 text-uppercase'>información financiera</p>
            </div>
 
            <div class='mb-2'>
            <!-- Ingresos mensuales - Actividad principal -->
              <div>
                <label for='activiEcon' class='col-md-6 col-lg-4 mt-2'>Ingresos mensuales - Actividad principal: &nbsp;</label><br>
                <div *ngIf='saveDataCC && v.InfActFinanciera.errors?.required' class='invalidText'>Campo requerido</div>  
                <div class='form-check form-check-inline'>
                  <input formControlName='InfActFinanciera' class='form-check-input' type='radio' value='Menos de RD$20 mil' name='InfActFinanciera'(click)='seeOtPromMen($event.target)'
                   [ngClass]='{ "is-invalid": saveDataCC && v.InfActFinanciera.errors}'>
                  <label class='form-check-label'>Menos de RD$20 mil</label>
                </div>
                <div class='form-check form-check-inline'>
                  <input formControlName='InfActFinanciera' class='form-check-input' type='radio' value='RD$20 mil a RD$50 mil' name='InfActFinanciera'(click)='seeOtPromMen($event.target)'
                  [ngClass]='{ "is-invalid": saveDataCC && v.InfActFinanciera.errors}'>
                  <label class='form-check-label'>RD$20 mil a RD$50 mil</label>
                </div>
                <div class='form-check form-check-inline'>
                  <input formControlName='InfActFinanciera' class='form-check-input' type='radio' value='RD$50 mil a RD$100 mil' name='InfActFinanciera'(click)='seeOtPromMen($event.target)'
                   [ngClass]='{ "is-invalid": saveDataCC && v.InfActFinanciera.errors}'>
                  <label class='form-check-label'>RD$50 mil a RD$100 mil</label>
                </div>
                <div class='form-check form-check-inline'>
                  <input formControlName='InfActFinanciera' class='form-check-input' type='radio' value='Más de RD$100 mil' name='InfActFinanciera'(click)='seeOtPromMen($event.target)'
                   [ngClass]='{ "is-invalid": saveDataCC && v.InfActFinanciera.errors}'>
                  <label class='form-check-label'>Más de RD$100 mil</label>
                </div>

                <div class='form-check form-check-inline'>                  
                  <input formControlName='InfActFinanciera' class='form-check-input' type='radio' value='Otros ingresos promedio mensual RD$' name='InfActFinanciera'(click)='seeOtPromMen($event.target)'
                   [ngClass]='{ "is-invalid": saveDataCC && v.InfActFinanciera.errors}'> 
                   <label class='form-check-label' for='otro'>Otros ingresos promedio mensual RD$</label>                
                  <input type='text' class='form-control' id='InfActEcoOtro' placeholder='0000000' name='InfActEcoOtro' formControlName='InfActEcoOtro' [ngClass]='{"is-invalid": saveDataCC && v.InfActEcoOtro.errors}'
                  *ngIf='otroIngPromMen'>                
                  <div *ngIf='saveDataCC && v.InfActEcoOtro.errors' class='invalid-feedback'>
                    <div *ngIf='v.InfActEcoOtro.errors.required || v.InfActEcoOtro.errors.whitespace'>Por favor, coloque los ingresos promedio mensual.</div>
                  </div>
                </div>
              </div>

              <!-- Descripción actividad económica de otros ingresos (actividad secundaria) -->
              <div class='mt-3'>
                <label for='InfActFinancieraDescr' class='form-label mb-0'>Descripción actividad económica de otros ingresos (actividad secundaria)</label> <br>
                <input type='text' class='form-control' id='InfActFinancieraDescr' placeholder='Actividad secundaria' name='InfActFinancieraDescr' formControlName='InfActFinancieraDescr' [ngClass]='{"is-invalid":saveDataCC && v.InfActFinancieraDescr.errors}'>
                
                <div *ngIf='saveDataCC && v.InfActFinancieraDescr.errors' class='invalid-feedback'>
                  <div *ngIf='v.InfActFinancieraDescr.errors.whitespace || v.InfActFinancieraDescr.errors.whitespace'>Por favor, coloque la actividad secundaria.</div>
                  <div *ngIf='v.InfActFinancieraDescr.errors.pattern'>La actividad secundaria debe contener sólo texto. [A-Z]</div>
                </div>
              </div>
              <br>
              <!-- Recursos publicos -->
              <div class='mt-2' >
                <label for='InfRecursosP' class='form-label mb-0'>¿Ha manejado o maneja recursos público durante los últimos 3 años?</label>
                <div *ngIf='saveDataCC && v.InfRecursosP.errors?.required' class='invalidText'>Campo requerido</div>   <br> 
                <div class='form-check form-check-inline'>
                  <input formControlName='InfRecursosP' class='form-check-input' type='radio' name='InfRecursosP' value='Si'(click)='recurPublic($event.target)' [ngClass]='{ "is-invalid": saveDataCC && v.InfRecursosP.errors}'>
                  <label class='form-check-label' for='Si'>Sí</label>
                </div>
                <div class='form-check form-check-inline'>
                  <input formControlName='InfRecursosP' class='form-check-input' type='radio' name='InfRecursosP' value='No'(click)='recurPublic($event.target)'  [ngClass]='{ "is-invalid": saveDataCC && v.InfRecursosP.errors}'>
                  <label class='form-check-label' for='sexoM'>No</label>
                </div>
                <div *ngIf='recuPu'>
                  <label for='InfRecursosPDescripcion' class='form-label mb-0'>Si su respuesta es afirmativa, especifique</label>
                  <input type='text' class='form-control' id='InfRecursosPDescripcion' placeholder='Actividad secundaria' name='InfRecursosPDescripcion' formControlName='InfRecursosPDescripcion' [ngClass]='{"is-invalid": saveDataCC && v.InfRecursosPDescripcion.errors}'>
                </div>
                

                <div *ngIf='saveDataCC && v.InfRecursosPDescripcion.errors' class='invalid-feedback'>
                  <div *ngIf='v.InfRecursosPDescripcion.errors.required || v.InfRecursosPDescripcion.errors.whitespace'>Por favor, especifique.</div>
                </div>
              </div>

              <!-- Poder publico -->
              <div class='mt-2'>
                <label for='InfPoderPublico' class='form-label mb-0'>¿Ha poseído o posee algun grado de poder público durante los últimos 3 años?</label>
                <div *ngIf='saveDataCC && v.InfPoderPublico.errors?.required' class='invalidText'>Campo requerido</div>   <br>
                <div class='form-check form-check-inline'>
                  <input formControlName='InfPoderPublico' class='form-check-input' type='radio' name='InfPoderPublico' value='Si'(click)='poderPub($event.target)' [ngClass]='{ "is-invalid": saveDataCC && v.InfPoderPublico.errors}'>
                  <label class='form-check-label' for='Si'>Sí</label>
                </div>
                <div class='form-check form-check-inline'>
                  <input formControlName='InfPoderPublico' class='form-check-input' type='radio' name='InfPoderPublico' value='No' (click)='poderPub($event.target)' [ngClass]='{ "is-invalid": saveDataCC && v.InfPoderPublico.errors}'>
                  <label class='form-check-label' for='sexoM'>No</label>
                </div>
                <div *ngIf='podPub'>
                  <label for='InfPoderPublicoDescripcion' class='form-label mb-0'>Si su respuesta es afirmativa, especifique</label>
                  <input type='text' class='form-control' id='InfPoderPublicoDescripcion' placeholder='Actividad secundaria' name='InfPoderPublicoDescripcion' formControlName='InfPoderPublicoDescripcion' [ngClass]='{"is-invalid": saveDataCC && v.InfPoderPublicoDescripcion.errors}'>
                </div>

                <div *ngIf='saveDataCC && v.InfPoderPublicoDescripcion.errors' class='invalid-feedback'>
                  <div *ngIf='v.InfPoderPublicoDescripcion.errors.required || v.InfPoderPublicoDescripcion.errors.whitespace'>Por favor, especifique.</div>
                  <div *ngIf='v.InfPoderPublicoDescripcion.errors.pattern'>Este campo debe contener sólo texto. [A-Z]</div>
                </div>
              </div>

              <!-- Persona reconocida o influencia publica -->
              <div class='mt-2'>
                <label for='InfInfluenciaPub' class='form-label mb-0'>¿Es persona reconocida o de influencia público?</label>
                <div *ngIf='saveDataCC && v.InfInfluenciaPub.errors?.required' class='invalidText'>Campo requerido</div>   <br>
                <div class='form-check form-check-inline'>
                  <input formControlName='InfInfluenciaPub' class='form-check-input' type='radio' name='InfInfluenciaPub' value='Si' (click)='influenciaPu($event.target)' [ngClass]='{ "is-invalid": saveDataCC && v.InfInfluenciaPub.errors}'>
                  <label class='form-check-label' for='Si'>Sí</label>
                </div>
                <div class='form-check form-check-inline'>
                  <input formControlName='InfInfluenciaPub' class='form-check-input' type='radio' name='InfInfluenciaPub' value='No' (click)='influenciaPu($event.target)' [ngClass]='{ "is-invalid": saveDataCC && v.InfInfluenciaPub.errors}'>
                  <label class='form-check-label' for='sexoM'>No</label>
                </div>
                <div *ngIf='inflPub'>
                  <label for='InfInfluenciaPubDescripcion' class='form-label mb-0'>Si su respuesta es afirmativa, especifique</label>
                  <input type='text' class='form-control' id='InfInfluenciaPubDescripcion' placeholder='Actividad secundaria' name='InfInfluenciaPubDescripcion' formControlName='InfInfluenciaPubDescripcion' [ngClass]='{"is-invalid": saveDataCC && v.InfInfluenciaPubDescripcion.errors}'>
                </div>

                <div *ngIf='saveDataCC && v.InfInfluenciaPubDescripcion.errors' class='invalid-feedback'>
                  <div *ngIf='v.InfInfluenciaPubDescripcion.errors.required || v.InfInfluenciaPubDescripcion.errors.whitespace'>Por favor, especifique.</div>
                  <div *ngIf='v.InfInfluenciaPubDescripcion.errors.pattern'>Este campo debe contener sólo texto. [A-Z]</div>
                </div>
              </div>
              
              <!-- Afirmativa alguna preguntas -->
              <div class='mt-2'>
                <label for='InfAfirmativaCon' class='form-label mb-0'>¿Es afirmativa alguna de las preguntas anteriores para su cónyuge, padres, abuelo, hijos, nietos, suegros, nueras o yernos?</label>
                <div *ngIf='saveDataCC && v.InfAfirmativaCon.errors?.required' class='invalidText'>Campo requerido</div>   <br>
                <div class='form-check form-check-inline'>
                  <input formControlName='InfAfirmativaCon' class='form-check-input' type='radio' name='InfAfirmativaCon' value='Si' (click)='afirmatOther($event.target)' [ngClass]='{ "is-invalid": saveDataCC && v.InfAfirmativaCon.errors}'>
                  <label class='form-check-label' for='Si'>Sí</label>
                </div>
                <div class='form-check form-check-inline'>
                  <input formControlName='InfAfirmativaCon' class='form-check-input' type='radio' name='InfAfirmativaCon' value='No' (click)='afirmatOther($event.target)' [ngClass]='{ "is-invalid": saveDataCC && v.InfAfirmativaCon.errors}'>
                  <label class='form-check-label' for='sexoM'>No</label>
                </div>
                <div *ngIf='AfirmToOther'>
                  <label for='InfAfirmativaConDescripcion' class='form-label mb-0'>Si su respuesta es afirmativa, especifique</label>
                  <input type='text' class='form-control' id='InfAfirmativaConDescripcion' placeholder='Actividad secundaria' name='InfAfirmativaConDescripcion' formControlName='InfAfirmativaConDescripcion' [ngClass]='{"is-invalid": saveDataCC && v.InfAfirmativaConDescripcion.errors}'>
                </div>

                <div *ngIf='saveDataCC && v.InfAfirmativaConDescripcion.errors' class='invalid-feedback'>
                  <div *ngIf='v.InfAfirmativaConDescripcion.errors.required || v.InfAfirmativaConDescripcion.errors.whitespace'>Por favor, especifique.</div>
                  <div *ngIf='v.InfAfirmativaConDescripcion.errors.pattern'>Este campo debe contener sólo texto. [A-Z]</div>
                </div>
              </div>

              
            </div>
          </div>


        </form>

        <div *ngIf='saveDataCC && v.errors' class='invalidText'>
          <div>Hay campos vacios, debe completarlos para guardar los datos.</div>
        </div>
      </div>
      
      <div class='modal-footer'>
        <div class='d-block'>
          <input type='checkbox' class='form-check-input' id='declaracion' (click)='aceptoDecl($event.target)'>
          <label class='form-check-label' for='declaracion' id='acepDeclaVerd'>Afirmo que todas las declaraciones contenidas en esta solicitud son verídicas, precisas y exactas.</label>
        </div>
        <div>
          <button type='button' class='btn btn-secondary' (click)='closeModalInsp()'>Cerrar</button>&nbsp;&nbsp;
          <a data-bs-dismiss='modal' id='clsModBtn' hidden></a>
          <button type='button' class='btn btnAseg' (click)='submitConoClient()'  [disabled]='btnShowSaveData'>Guardar datos</button>
        </div>
        
        
      </div>
    </div>
  </div>
</div>





 <!-- *****
    Formulario Inspeccion 
  ********-->
  
  <div class='container col-11 col-md-12 mt-5' id='cntFormInsp'>
		<form [formGroup]='formInspeccion' (ngSubmit)='onSubmit()' class='row g-3 needs-validation px-lg-4 py-2' novalidate>
			 
      <div class='row px-0 mx-0 pt-md-2'>
        <div>
          <p class='regBold'>Complete los siguientes datos</p>
        </div>

        <!-- Cant. Pasajeros -->
        <div class='col-md-6 col-lg-4 mt-2'>
          <label for='cantPasajeros' class='form-label mb-0'><img class='formInspeIcon' src='assets/img/canPersonas.svg'> Cantidad Pasajeros</label>
          <input type='text' class='form-control formLine' id='cantPasajeros' placeholder='0' name='cantPasajeros' maxlength='2' formControlName='cantPasajeros' [ngClass]='{"is-invalid": submitted && f.cantPasajeros.errors}'>
          <div *ngIf='submitted && f.cantPasajeros.errors' class='invalid-feedback'>
            <div *ngIf='f.cantPasajeros.errors.required || f.cantPasajeros.errors.whitespace'>Por favor, coloque la cantidad de pasajeros.</div>
            <div *ngIf='f.cantPasajeros.errors.pattern'>La cantidad de pasajeros debe contener sólo carácteres numericos. [0-9]</div>
          </div>
        </div>

        <!--Cant. Cilindros -->
        <div class='col-md-6 col-lg-4 mt-2'>
          <label for='Colindros' class='form-label mb-0'><img class='formInspeIcon' src='assets/img/cilindro.svg'> Cilindros</label>
          <input type='text' class='form-control formLine' id='Colindros' placeholder='0' name='Colindros' formControlName='Colindros' [ngClass]='{"is-invalid": submitted && f.Colindros.errors}'>
          <div *ngIf='submitted && f.Colindros.errors' class='invalid-feedback'>
            <div *ngIf='f.Colindros.errors.required || f.Colindros.errors.whitespace'>Por favor, coloque la cantidad de Cilindros.</div>
            <div *ngIf='f.Colindros.errors.pattern'>La cantidad de Cilindros debe contener sólo carácteres numericos. [0-9]</div>
          </div>
        </div>

        <!-- Odometro -->
        <div class='col-md-6 col-lg-4 mt-2'>
          <label for='Odometro' class='form-label mb-0'><img class='formInspeIcon' src='assets/img/odometro.svg'> Odómetro</label>
          <input type='text' class='form-control formLine' id='Odometro' placeholder='0' name='Odometro' formControlName='Odometro' [ngClass]='{"is-invalid": submitted && f.Odometro.errors}'>
          <div *ngIf='submitted && f.Odometro.errors' class='invalid-feedback'>
            <div *ngIf='f.Odometro.errors.required || f.Odometro.errors.whitespace'>Por favor, coloque la cantidad de Odómetro.</div>
            <div *ngIf='f.Odometro.errors.pattern'>La cantidad de Odómetro debe contener sólo carácteres numericos. [0-9]</div>
          </div>
        </div>

        <!-- Unidad Odómetro -->
        <div class='col-md-6 col-lg-4 mt-2'>
          <label for='UniOdom' class='form-label mb-0'><img class='formInspeIcon' src='assets/img/odometro.svg'> Unidad Odómetro</label>
          <select class='form-select formLine' id='UniOdom' name='UniOdom' formControlName='UniOdom' [ngClass]='{ "is-invalid": submitted && f.UniOdom.errors }'>
            <option selected disabled value=''>Seleccionar</option>
            <option selected value='KM'>KM</option>
            <option selected value='Milla'>Milla</option>
          </select>
          <div *ngIf='submitted && f.UniOdom.errors' class='invalid-feedback'>
            <div *ngIf='f.UniOdom.errors.required'>Por favor, seleccione la únidad de odómetro.</div>
          </div>
        </div>

<!-- Imagenes del vehiculo -->

        <div class='mt-3'>
        <p class='regBold'>Imágenes del vehículo</p>
        </div>

      <!-- Parte Frontal -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto de la parte frontal</p>
          <div class='row'  [ngClass]='{"is-invalid": submitted && f.imgPartFront.errors}'>
            <div class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotPartFrontal'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseFrontal($event.target)' formControlName='imgPartFront' [ngClass]='{"is-invalid": submitted && f.imgPartFront.errors}'accept='image/*' capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgPartFront.errors' class='invalid-feedback'>
            <div *ngIf='f.imgPartFront.errors.required'>Por favor, agregue la foto de la parte frontal del vehículo.</div>
          </div>
        </div>

        <!-- PArte Superios -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto de la parte superior (techo)</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgPartSup.errors}'>
            <div  class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotPartSuper'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseSupe($event.target)' formControlName='imgPartSup' [ngClass]='{"is-invalid": submitted && f.imgPartSup.errors}'accept='image/*' capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgPartSup.errors' class='invalid-feedback'>
            <div *ngIf='f.imgPartSup.errors.required'>Por favor, agregue la foto de la parte superior del vehículo.</div>
          </div>
        </div>    
       

        <!-- Lateral derecho -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto del lateral derecho</p>
          <div class='row'  [ngClass]='{"is-invalid": submitted && f.imgLatDerecho.errors}'>
            <div class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotLatDerecha'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseLatDer($event.target)' formControlName='imgLatDerecho' [ngClass]='{"is-invalid": submitted && f.imgLatDerecho.errors}'accept='image/*' capture='camera'>
            </div>
          </div>    
          <div *ngIf='submitted && f.imgLatDerecho.errors' class='invalid-feedback'>
            <div *ngIf='f.imgLatDerecho.errors.required'>Por favor, agregue la foto del lateral derecho del vehículo.</div>
          </div>
        </div>

    
        <!-- Parte Trasera -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto de la parte trasera</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgPartTrasera.errors}'>
            <div class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotTrasera'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseTra($event.target)' formControlName='imgPartTrasera' [ngClass]='{"is-invalid": submitted && f.imgPartTrasera.errors}'accept='image/*' capture='camera'>
            </div>
          </div>   
          <div *ngIf='submitted && f.imgPartTrasera.errors' class='invalid-feedback'>
            <div *ngIf='f.imgPartTrasera.errors.required'>Por favor, agregue la foto de la parte trasera del vehículo.</div>
          </div>
        </div>

        <!-- Interior Baúl -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto del interior del baúl</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgIntBaul.errors}'>
            <div  class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotInteriorBaul'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseIntBaul($event.target)' formControlName='imgIntBaul' [ngClass]='{"is-invalid": submitted && f.imgIntBaul.errors}'accept='image/*' capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgIntBaul.errors' class='invalid-feedback'>
            <div *ngIf='f.imgIntBaul.errors.required'>Por favor, agregue la foto del interior del baúl del vehículo.</div>
          </div>
        </div>       

        <!-- Lateral izquierdo -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto del lateral izquierdo</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgLatIzq.errors}'>
            <div class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotLatIzquierda'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseLatIzq($event.target)' formControlName='imgLatIzq' [ngClass]='{"is-invalid": submitted && f.imgLatIzq.errors}'accept='image/*' capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgLatIzq.errors' class='invalid-feedback'>
            <div *ngIf='f.imgLatIzq.errors.required'>Por favor, agregue la foto del lateral izquierdo del vehículo.</div>
          </div>
        </div>

        <!-- Chasis -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto del chasis</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgChasis.errors}'>
            <div  class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotChasis'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseChasis($event.target)' formControlName='imgChasis' [ngClass]='{"is-invalid": submitted && f.imgChasis.errors}'accept='image/*' capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgChasis.errors' class='invalid-feedback'>
            <div *ngIf='f.imgChasis.errors.required'>Por favor, agregue la foto del chasis del vehículo.</div>
          </div>
        </div>

        <!-- Odómetro -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto del odómetro</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgOdometro.errors}'>
            <div class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotOdometro'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseOdo($event.target)' formControlName='imgOdometro' [ngClass]='{"is-invalid": submitted && f.imgOdometro.errors}'accept='image/*'capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgOdometro.errors' class='invalid-feedback'>
            <div *ngIf='f.imgOdometro.errors.required'>Por favor, agregue la foto del odómetro del vehículo.</div>
          </div>
        </div>

       <!-- Interior tablero -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto del interior tablero</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgIntTablero.errors}'>
            <div  class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotIntDelantero'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseIntDel($event.target)' formControlName='imgIntTablero' [ngClass]='{"is-invalid": submitted && f.imgIntTablero.errors}'accept='image/*'capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgIntTablero.errors' class='invalid-feedback'>
            <div *ngIf='f.imgIntTablero.errors.required'>Por favor, agregue la foto del interior tablero del vehículo.</div>
          </div>
        </div>

        <!-- Interior trasero -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto del asiento interior traseros</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgAsiIntTrasero.errors}'>
            <div  class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotIntTrasero'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseInTra($event.target)' formControlName='imgAsiIntTrasero' [ngClass]='{"is-invalid": submitted && f.imgAsiIntTrasero.errors}'accept='image/*' capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgAsiIntTrasero.errors' class='invalid-feedback'>
            <div *ngIf='f.imgAsiIntTrasero.errors.required'>Por favor, agregue la foto del asiento interior traseros del vehículo.</div>
          </div>
        </div>

        <!-- Motor -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto del motor</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgAsiIntTrasero.errors}'>
            <div  class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotMotor'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseMotor($event.target)' formControlName='imgMotor' [ngClass]='{"is-invalid": submitted && f.imgMotor.errors}' accept='image/*' capture='camera'>
            </div>
          </div>     
          <div *ngIf='submitted && f.imgMotor.errors' class='invalid-feedback'>
            <div *ngIf='f.imgMotor.errors.required'>Por favor, agregue la foto del motor del vehículo.</div>
          </div>
        </div>

        <!-- Repuesta/Gato y llave -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto del respuesto/ gato y llave</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgRepuestaGL.errors}'>
            <div  class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='Respuesto/Gato y llave' id='FotRepuesto'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseGato($event.target)' formControlName='imgRepuestaGL' [ngClass]='{"is-invalid": submitted && f.imgRepuestaGL.errors}' accept='image/*' capture='camera'>
            </div>
          </div>     
          <div *ngIf='submitted && f.imgRepuestaGL.errors' class='invalid-feedback'>
            <div *ngIf='f.imgRepuestaGL.errors.required'>Por favor, agregue la foto del respuesto/ Gato y llave.</div>
          </div>
        </div>
        <hr>
     

      <!-- Imagenes de Documentos -->
    
        <div>
        <p class='mt-2 regBold'>Imágenes de sus documentos</p>
        </div>

      <!-- Frontal Cedula/Documento Identidad -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto frontal de la Cédula/Documento</p>
          <div class='row'  [ngClass]='{"is-invalid": submitted && f.imgCedula.errors}'>
            <div class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotCedula'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseCedula($event.target)' formControlName='imgCedula' [ngClass]='{"is-invalid": submitted && f.imgCedula.errors}'accept='image/*' capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgCedula.errors' class='invalid-feedback'>
            <div *ngIf='f.imgCedula.errors.required'>Por favor, agregue la foto delantera de la Cédula/Documento de identidad.</div>
          </div>
        </div>

        <!-- Trasera Cedula/Documento Identidad -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto trasera de la Cédula/Documento</p>
          <div class='row'  [ngClass]='{"is-invalid": submitted && f.imgCedTrasera.errors}'>
            <div class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotCedTrasera'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBasecedTrase($event.target)' formControlName='imgCedTrasera' [ngClass]='{"is-invalid": submitted && f.imgCedTrasera.errors}'accept='image/*' capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgCedTrasera.errors' class='invalid-feedback'>
            <div *ngIf='f.imgCedTrasera.errors.required'>Por favor, agregue la foto trasera de la Cédula/Documento de identidad.</div>
          </div>
        </div>

        <!-- Matricula/BL -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto de la matricula/BL</p>
          <div class='row'  [ngClass]='{"is-invalid": submitted && f.imgMatricula.errors}'>
            <div class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotMatricula'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseMatri($event.target)' formControlName='imgMatricula' [ngClass]='{"is-invalid": submitted && f.imgMatricula.errors}'accept='image/*' capture='camera'>
            </div>
          </div>    
          <div *ngIf='submitted && f.imgMatricula.errors' class='invalid-feedback'>
            <div *ngIf='f.imgMatricula.errors.required'>Por favor, agregue la foto de la matricula/BL del vehículo.</div>
          </div>
        </div>
        
        <!-- Licencia -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Foto de la licencia</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgLicencia.errors}'>
            <div class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotLicencia'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseLince($event.target)' formControlName='imgLicencia' [ngClass]='{"is-invalid": submitted && f.imgLicencia.errors}'accept='image/*' capture='camera'>
            </div>
          </div>   
          <div *ngIf='submitted && f.imgLicencia.errors' class='invalid-feedback'>
            <div *ngIf='f.imgLicencia.errors.required'>Por favor, agregue la foto de la licencia.</div>
          </div>
        </div>

        <!-- Otro Documento -->
        <div class='col-md-6 ctnImgInspeccion'>
          <p class='mb-1'>Otro documento</p>
          <div class='row' [ngClass]='{"is-invalid": submitted && f.imgOtroDocumento.errors}'>
            <div  class='col-4'>
              <img class='center' src='assets/img/upload.svg' alt='imagen de la parte frontal' id='FotOtro'>
            </div>
            <div class='col-7 px-0 d-flex align-items-center'>
              <input class='form-control form-control-sm mt-2' type='file' (change)='getBaseFotOtro($event.target)' formControlName='imgOtroDocumento' [ngClass]='{"is-invalid": submitted && f.imgOtroDocumento.errors}'accept='image/*' capture='camera'>
            </div>
          </div>
          <div *ngIf='submitted && f.imgOtroDocumento.errors' class='invalid-feedback'>
            <div *ngIf='f.imgOtroDocumento.errors.required'>Por favor, agregue la foto del interior del baúl del vehículo.</div>
          </div>
        </div>

      </div>
			
			<div class='col-12 row px-md-0 mt-3 d-flex justify-content-center'>
			  <div class='my-3 col-12 d-flex flex-wrap align-content-center justify-content-lg-start'>
				 <button class='btn btnAseg text-uppercase mx-auto regBold mx-md-0' type='submit'>Enviar Inspección ></button>
			  </div>
			</div>
	  
			
		  </form>
	 </div>

 
</section>
