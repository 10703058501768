import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewChildCompService {

  hideMenuBool:any=false;
  constructor() { }

  listChild(elm:boolean){
    this.hideMenuBool = elm;
  }
  hideMenu(){
    return this.hideMenuBool;
  }

}
