<section class='container mt-lg-4 main_container'>
	<div class='row'>
		<div class='col-12 col-lg-6 ps-lg-5 row d-flex justify-content-center justify-content-lg-start' id='txtInd'>
			<div class='col-8 col-lg-9 mt-3'>
				<h1 class='textIndex acentText text-center text-lg-start'>ASEGÚRATE<br>DE <span class='tw'></span></h1>
			</div>
			<div class='col-12'>
				<span class='regBold'>¿Cómo asegurarme?</span>
				<ol>
					<li>Ingresa los datos.</li>
					<li>Encuentra el seguro que necesitas.</li>
					<li>Asegúrate con la póliza de tu preferencia.</li>
				</ol>
			</div>
		</div>
		<div class='col-12 col-lg-6'>
			<h2 class='acentText text-center mt-2'>COTIZA, ELIGE Y COMPRA</h2>
			<div class='row d-flex justify-content-center mt-4'>
				<!-- Loading gif while services load -->
				<!--	 <div class='contServ' id='ctnLazyLoad' *ngIf='lazyLoading'>
						<h5>Buscando servicios</h5>						
						<img src='assets/img/gifAseg.gif' class='center' alt='loading'>
						<p class='text-center mb-0'>Un momento por favor</p>
					</div> -->

				<p class="skeleton_p" *ngIf='lazyLoading'>Buscando servicios</p>
				<div class='skeleton' *ngIf='lazyLoading'>
				</div>


				<!-- Principal container service - AUTO -->
				<div class='contServ col-8 col-md-4 col-lg-6 px-0' id='contPrServ' *ngFor='let principal of servPrinc'>
					<h3 class='text-uppercase text-center regBold mt-2'>{{principal.Producto}}</h3>
					<img class='center mt-3' src={{principal.UrlIcono}} alt="icono {{principal.Producto}}">
					<div class="d-grid gap-2 mt-3">
						<button class="btn btnAseg regBold" type="button" (click)='goToform(principal.Producto)'
							attr.aria-label="Cotizar {{principal.Producto}}">{{principal.Descripcion}}</button>
					</div>
				</div>

			</div>
		</div>
	</div>

	<!-- Contenedores pequenos -->
	<div class='row p-3 mt-md-3 ps-lg-5 d-flex justify-content-around justify-content-md-start'>

		<div class='contServ noAvailable contPeq col-5 col-md-3 col-lg-2 px-0 mb-2 mx-md-4 mx-lg-3 mb-md-4 tooltipTop'
			*ngFor='let servicios of servPeq'>
			<p class='text-uppercase text-center regBold mt-4 mb-0'>{{servicios.Producto}}</p>
			<img class='center mt-2' src={{servicios.UrlIcono}} alt="icono {{servicios.Producto}}">
			<div class='d-grid gap-2 mt-3'>
				<button class='btn btnAseg regBold' type='button'
					attr.aria-label="Cotizar {{servicios.Producto}}">{{servicios.Descripcion}}</button>
				<span class='tooltiptext'>Próximamente</span>
			</div>
		</div>
	</div>
</section>