<!-- Gif loading -->
<div style='background-color:rgba(238, 238, 238, 0.767); width:100%; height: 100%; z-index:9999999!important;position:fixed; top:0;  left:0; display: flex; flex-direction:column; justify-content: center;  align-items: center;' id='progress' *ngIf='loading'>
	<h3 class='regBold text-center'>¡Enviando mensaje!<br><br>Estamos enviando su mensaje por favor espere.</h3>
	<img src="assets/img/gifAseg.gif" width='250px'>
</div>

<section class='container mt-2'>
<h1 class='regBold text-center'>Contacto</h1>
<div class='row d-flex justify-content-center'>
 <div class='row col-lg-8 d-flex justify-content-center'>
  <h4 class='mt-3 mb-0 regBold'>Envíanos un mensaje</h4>
  <form [formGroup]='formContacto' (ngSubmit)='onSubmit()' class='row g-3 needs-validation mt-0' novalidate>
   <div class='col-12'>
     <label for='comentarioCon' class='form-label'>Mensaje:</label>
     <textarea class='form-control' id='comentarioCon' name='comentarioCon' placeholder='Escribir mensaje...' formControlName='comentarioCon' [ngClass]='{"is-invalid": submitted && f.comentarioCon.errors}'></textarea>
     <div *ngIf='submitted && f.comentarioCon.errors' class='invalid-feedback'>
      <div *ngIf='f.comentarioCon.errors.required'>Por favor coloque el mensaje.</div>
    </div>
   </div>  
 
   <div class='col-md-6'>
    <label for='nombre' class='form-label'>Nombre:</label>
    <input type='text' class='form-control' id='nombre' placeholder='Nombre' name='nombreCon' formControlName='nombreCon' [ngClass]='{"is-invalid": submitted && f.nombreCon.errors}'>
    <div *ngIf='submitted && f.nombreCon.errors' class='invalid-feedback'>
      <div *ngIf='f.nombreCon.errors.required'>Por favor coloque el nombre.</div>
      <div *ngIf='f.nombreCon.errors.pattern'>El nombre debe contener sólo texto. [A-Z]</div>
    </div>
   </div>
 
   <div class='col-md-6'>
    <label for='correo' class='form-label'>Correo</label>
    <input type='email' class='form-control' id='correo' name='correoCon' placeholder='Correo' formControlName='correoCon' [ngClass]='{ "is-invalid": submitted && f.correoCon.errors}'>
    <div *ngIf='submitted && f.correoCon.errors' class='invalid-feedback'>
      <div *ngIf='f.correoCon.errors.required'>Por favor coloque el correo.</div>
      <div *ngIf='f.correoCon.errors.email'>Debe ser un correo valido.</div>
    </div>
   </div>
   
   <div class='col-12 row px-md-0 d-flex justify-content-end'>
     <!-- <div class='m-3 col-md-9 ps-4 pe-lg-3 mx-md-0 mb-lg-0 d-flex justify-content-md-end'>
      <re-captcha (resolved)='resolved($event)' [siteKey]='key'></re-captcha>
     </div> -->
     <div class='mt-3 col-md-2 col-lg-1 d-flex flex-wrap align-content-center justify-content-end'>
       <button class='btn btnAseg' type='submit'>Enviar</button>
     </div>
   </div>
 </form>
 </div>
 <div class='col-lg-3 d-flex justify-content-center'>
  <img class='center' src='assets/img/soldado.svg' id='imgSold' alt='Icono soldadito'>
 </div>
 <div class='row col-lg-11 d-flex justify-content-center'>
  <h4 class='mt-3 regBold'>Canales de contacto</h4>
  <p>También puedes ponerte en contacto con nosotros a través de alguno de nuestros canales alternos de contacto.</p>
  <div class='my-md-4 my-2 row'>
   <div class='col-md-3 col-6'>
    <img class='center iconsCtc' src='assets/img/ctcWhat.png' alt='Icono de whatsApp'>
    <p class='text-center' (click)='goWhatapp()'><a class='aCtc' [href]='callUs'>809-620-2524</a></p>
   </div>
   <div class='col-md-3 col-6'>
     <a class='linkRedes' href='https://fb.me/asegurate.do' target='_blank'>
    <img class='center iconsCtc' src='assets/img/ctcFb.png' alt='Icono de Facebook'>
    <p class='text-center'>@asegurate.do</p></a>
   </div>
   <div class='col-md-3 col-6'>
     <a class='linkRedes' href='https://www.instagram.com/asegurate.do/' target='_blank'>
        <img class='center iconsCtc' src='assets/img/ctcIg.png' alt='Icono de Instagram'>
    <p class='text-center'>@asegurate.do</p>
     </a>
   
   </div>
   <div class='col-md-3 col-6'>
    <img class='center iconsCtc' src='assets/img/ctcEmail.png' alt='Icono de Correo'>
    <p class='text-center'><a class='aCtc' [href]='emailstring'>info@asegurate.do</a></p>
   </div>
  </div>
 </div>
</div>

</section>



