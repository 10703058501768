<!-- Gif loading  -->
<div
  style='background-color:rgba(238, 238, 238, 0.767); width:100%; height: 100%; z-index:9999999!important;position:fixed; top:0;  left:0; display: flex; flex-direction:column; justify-content: center;  align-items: center;'
  id='progress' *ngIf='loading'>
  <h3 class='regBold text-center'>¡Buscando ofertas!<br><br>Estamos consultando entre varias aseguradoras para encontrar
    las ofertas que mejor se ajusten a tu perfil.
  </h3>
  <img src="assets/img/gifAseg.gif">
  <h5 class='regBold text-center px-2'>Este proceso puede tomar unos minutos, por favor espere.</h5>
</div>

<section class='container mt-4 mt-md-3 px-0'>
  <div class='row d-flex justify-content-center'>
    <!-- Texto -->
    <div class='col-lg-3 px-0 col-11 row' id='lefInfo'>
      <div class='col-5 col-md-5 col-lg-12'>
        <h1 class='text-center text-uppercase regBold'>AUTO</h1>
        <img class='my-1 my-lg-3' src='assets/img/carToy.png'
          alt='auto rojo imagen ne formulario de poliza de vehiculo'>
      </div>
      <div class='col-7 col-md-7 col-lg-12'>
        <h3 class='acentText'>ASEGÚRATE DE TENER EL MEJOR PRECIO</h3>
      </div>
    </div>

    <!-- Formulario -->
    <div class='mx-auto col-lg-8 col-11 mt-4'>
      <form [formGroup]='formVehiculo' (ngSubmit)='AbrirModal()' class='row g-3 needs-validation' novalidate>
        <p class='mb-2 ps-0 txtBold text-lg-center'>Por favor complete los siguientes datos</p>

        <!-- Tipo de VEhiculo -->
        <div class='col-md-6 col-lg-4'>
          <label for='tipoVeh' class='form-label '><img class='formIcon' src='assets/img/fTipoVeh.svg'> Tipo de
            vehículo</label>
          <select class='form-select formLine' id='tipoVeh' name='tipoVeh' formControlName='tipoVeh'
            [ngClass]='{ "is-invalid": submitted && f.tipoVeh.errors }' (change)='fillCilindraje($event.target)'>
            <option selected disabled value=''>Seleccionar</option>
            <option *ngFor='let tipo of tipoVehi' [value]='tipo.IdTipoVehiculo'>{{tipo.TipoVehiculo}}</option>
          </select>
          <div *ngIf='submitted && f.tipoVeh.errors' class='invalid-feedback'>
            <div *ngIf='f.tipoVeh.errors.required'>Por favor seleccione el tipo de vehículo.</div>
          </div>
        </div>

        <!-- Tipo de Seguro -->
        <div class='col-md-6 col-lg-4'>
          <label for='tipoSeg' class='form-label'><img class='formIcon' src='assets/img/fTipoSeg.svg'> Tipo de
            seguro</label>
          <select class='form-select formLine' id='tipoSeg' name='tipoSeg' formControlName='tipoSeg'
            [ngClass]='{ "is-invalid": submitted && f.tipoSeg.errors }' (change)='seeTypeSeg($event.target)'>
            <option selected disabled value=''>Seleccionar</option>
            <option value='LEY'>Seguro de Ley</option>
            <option value='FULL'>Seguro de Full</option>
          </select>
          <div *ngIf='submitted && f.tipoSeg.errors' class='invalid-feedback'>
            <div *ngIf='f.tipoSeg.errors.required'>Por favor seleccione el tipo de seguro.</div>
          </div>
        </div>

        <!-- Tipo de Cilindraje -->
        <div class='col-md-6 col-lg-4' *ngIf='showCilindro'>
          <label for='tipoCil' class='form-label'><img class='formIcon' src='assets/img/pistones.png'> Subtipo
            vehículo</label>
          <select class='form-select formLine' id='tipoCil' name='tipoCil' formControlName='tipoCil'
            [ngClass]='{ "is-invalid": submitted && f.tipoCil.errors }'>
            <option selected disabled value=''>Seleccionar</option>
            <option *ngFor='let cilind of cilindValue' [value]='cilind'>{{cilind}}</option>
          </select>
          <div *ngIf='submitted && f.tipoCil.errors' class='invalid-feedback'>
            <div *ngIf='f.tipoCil.errors.required'>Por favor seleccione el tipo de cilindraje.</div>
          </div>
        </div>

        <!-- Marca -->
        <div class='col-md-6 col-lg-4'>
          <label for='marca' class='form-label'><img class='formIcon' src='assets/img/fMarca.svg'> Marca</label>
          <select class='form-select formLine' id='marca' name='marca' formControlName='marca'
            [ngClass]='{"is-invalid": submitted && f.marca.errors}' (change)='showModelo($any($event.target).value)'>
            <option selected disabled value=''>Seleccionar</option>
            <option *ngFor='let veh of marcaVehi' [value]='veh.IdMarca'>{{veh.Marca}}</option>
          </select>
          <div *ngIf='submitted && f.marca.errors' class='invalid-feedback'>
            <div *ngIf='f.marca.errors.required'>Por favor seleccione la marca.</div>
          </div>
        </div>

        <!-- Modelo -->
        <div class='col-md-6 col-lg-4'>
          <label for='modelo' class='form-label'><img class='formIcon' src='assets/img/fModelo.svg'> Modelo</label>
          <select class='form-select formLine' id='modelo' name='modelo' formControlName='modelo'
            [ngClass]='{"is-invalid": submitted && f.modelo.errors}'>
            <option selected disabled value=''>Seleccionar</option>
            <option *ngFor='let marca of modeloVehi' [value]='marca.IdModelo'>{{marca.Modelo}}</option>
          </select>
          <div *ngIf='submitted && f.modelo.errors' class='invalid-feedback'>
            <div *ngIf='f.modelo.errors.required'>Por favor seleccione el tipo de modelo.</div>
          </div>
        </div>

        <!-- Anio -->
        <div class='col-md-6 col-lg-4'>
          <label for='anio' class='form-label'><img class='formIcon' src='assets/img/fAnio.svg'> Año</label>
          <input type='text' class='form-control formLine' id='anio' placeholder='0000' name='anio' minlength='4'
            maxlength='4' formControlName='anio' [ngClass]='{"is-invalid": submitted && f.anio.errors}' mask="0000">
          <div *ngIf='submitted && f.anio.errors' class='invalid-feedback'>
            <div *ngIf='f.anio.errors.required'>Por favor coloque el año de vehículo.</div>
            <div *ngIf='f.anio.errors.minlength'>El año no puede ser inferior a 4 carácteres, ej. 2024</div>
            <div *ngIf='f.anio.errors.pattern'>El año debe contener sólo carácteres numericos. [0-9]</div>
          </div>
        </div>

        <!-- Valor -->
        <div class='col-md-6 col-lg-4' style="position: relative;">
          <label for='valorVeh' class='form-label'>
            <img class='formIcon' src='assets/img/fValor.svg'> Valor del vehículo
          </label>
          <div style="position: relative;">
            <span style="position: absolute; left: 3px; top: 50%; transform: translateY(-50%); pointer-events: none;">RD$</span>
            <input type='text' class='form-control formLine' id='valorVeh' placeholder='00.0' name='valorVeh' minlength='6' maxlength='12'
              formControlName='valorVeh' [ngClass]='{"is-invalid": submitted && f.valorVeh.errors}' 
              mask="separator.2" thousandSeparator="," style="padding-left: 35px;">
          </div>
          <div *ngIf='submitted && f.valorVeh.errors' class='invalid-feedback'>
            <div *ngIf='f.valorVeh.errors.required'>Por favor coloque el valor del vehículo.</div>
          </div>
        </div>
        
        

        <!-- Provincia / Circulacion -->
        <div class='col-md-6 col-lg-4'>
          <label for='provincia' class='form-label'><img class='formIcon' src='assets/img/fProvin.svg'>
            Provincia</label>
          <select class='form-select formLine' id='provincia' name='provincia' formControlName='provincia'
            [ngClass]='{"is-invalid": submitted && f.provincia.errors}'>
            <option selected disabled value=''>Seleccionar</option>
            <option *ngFor='let provinc of provin' [value]='provinc.IdCirculacion'>{{provinc.circulacion}}</option>
          </select>
          <div *ngIf='submitted && f.provincia.errors' class='invalid-feedback'>
            <div *ngIf='f.provincia.errors.required'>Por favor seleccione la provincia.</div>
          </div>
        </div>

        <!-- Uso / Actividades -->
        <div class='col-md-6 col-lg-4'>
          <label for='usoVeh' class='form-label'><img class='formIcon' src='assets/img/fUso.svg'> Uso del
            vehículo</label>
          <select class='form-select formLine' id='usoVeh' name='usoVeh' formControlName='usoVeh'
            [ngClass]='{"is-invalid": submitted && f.usoVeh.errors}'>
            <option selected disabled value=''>Seleccionar</option>
            <option *ngFor='let uso of usoVehi' [value]='uso.IdActividad'>{{uso.Actividad}}</option>
          </select>
          <div *ngIf='submitted && f.usoVeh.errors' class='invalid-feedback'>
            <div *ngIf='f.usoVeh.errors.required'>Por favor seleccione el uso del vehículo.</div>
          </div>
        </div>

        <!-- Equipamentos -->
        <div class="accordion col-md-6 col-lg-4" id="accordionExample">

          <div class="accordion-item ">
            <h2 class="accordion-header" id="equipaBtn">
              <button class="accordion-button formLine collapsed btn btn-light px-1" type="button"
                data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                aria-controls="collapseThree">
                <label for='equipamientos' class='form-label'><img class='formIcon' src='assets/img/fEquip.svg'>
                  Equipamientos</label>
              </button>
            </h2>
            <div id='collapseThree' class='accordion-collapse collapse' aria-labelledby='equipaBtn'
              data-bs-parent='#accordionExample'>
              <div class='accordion-body'>
                <div class='form-check' *ngFor='let equipa of acessorios; let i = index'>
                  <input class='form-check-input equiCheck' type='checkbox' [value]='equipa.IdAccesorio'
                    (change)='seeSelect($event.target)' formControlName='equipamientos'
                    id='opcion{{equipa.IdAccesorio}}' name='opc{{i}}'>
                  <label class='form-check-label txtUpper' for='opc{{i}}'>
                    {{equipa.Accesorio}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='col-8 row px-md-0 mt-3 d-flex justify-content-center'>
          <!-- <div class='m-3 col-md-9 pe-lg-3 mx-md-0 mb-lg-0 d-flex justify-content-md-end'>
        <re-captcha (resolved)='resolved($event)' [siteKey]='key'></re-captcha>
       </div> -->
          <div class='mt-3 col-12 d-flex flex-wrap align-content-center justify-content-lg-end'>
            <button class='btn btnAseg text-uppercase mx-auto regBold mx-md-0' type='submit' [disabled]='btnShow'
              id='tremCondButton'>cotizar ></button>
          </div>
        </div>
        <div class="col-12 mb-4 form-check d-flex justify-content-lg-end">
          <input type="checkbox" class="form-check-input" id="acepTermCond" (click)='acepto($event.target)'>
          <label class="form-check-label" for="acepTermCond" id='acepTermLab'>&#160;Acepto los <a
              href='terminos-y-condiciones' target='_blank'>términos y condiciones</a> y <a href='politicas-de-cookies'
              target='_blank'>política de cookies</a>
          </label>
        </div>


      </form>


    </div>

  </div>







  <!-- <div *ngIf="isModalOpen" class="modal-overlay"> -->
  <!-- <div *ngIf="isModalOpen" class="modal-overlay"> -->
  <div *ngIf="isModalOpen" class="modal-overlay" (click)="CerrarModal()">
		<div class="modal-content" (click)="$event.stopPropagation()">
			<div>
				<div class="modal-header">
					<h1 class="modal-title">¡Aviso!</h1>
				</div>
				<div class="modal-body">
					<p class="modal-description">
						Por favor, proporciona tu correo electrónico para recibir actualizaciones importantes sobre tu seguro, como coberturas, renovaciones, beneficios y ofertas exclusivas.
            ¡Mantente informado y asegura tu tranquilidad!</p>
					<!-- <p class="modal-description">
						Por favor, ingresa tu correo electrónico para que podamos mantenernos en contacto. Queremos
						enviarte actualizaciones sobre tu seguro, incluyendo información sobre coberturas, renovaciones
						y beneficios. Con tu correo electrónico, podremos notificarte sobre cambios en tu póliza,
						ofertas exclusivas y consejos útiles. ¡Mantente conectado y asegura tu tranquilidad! </p> -->
					<form [formGroup]="emailForm" (ngSubmit)="OptenerIpYCorreo()" style="border: none;">
						
							<label for="email" id="email">Email:</label>
							<input type="email" id="campo-email" formControlName="email" placeholder="Ingresar correo..."
								[ngClass]='{"is-invalid": submittedModal && emailF.email.errors}' />
							<div *ngIf='submittedModal && emailF.email.errors' class="campo-error">
								<div *ngIf='emailF.email.errors.required'>Por favor debe completar este campo.</div>
                <div *ngIf='emailF.email.errors.email'>El formato de email debe ser (ejemplo@ejemplo.com).</div>
							</div>
						

						<div class="modal-buttons">
							<!-- <button type="button" class="cancel-btn" (click)="CerrarModal()">Cancelar</button> -->
							<button type="submit" class="submit-btn"> Confirmar </button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>





</section>

<section class='container mt-3'>
  <div class='row' id='downCont'>
    <div class='col-lg-4 col-md-2 ps-3 d-flex justify-content-lg-end'>
      <a href=''><img src='assets/img/leftArrow.svg' alt='Flecha para ir una pagina atras' class='backsArrows'></a>
    </div>
    <!-- Publicidad -->
    <div class='col-12 col-md-9 col-lg-8 my-4'>
      <img class='center' id='asegurate1' src='assets/img/asegurate1.png' alt='Busca compara y encuentra, seguros'>
    </div>
  </div>
</section>