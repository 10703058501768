import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener,
} from '@angular/core';
import Swal from 'sweetalert2';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../services/data.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ComparadorComponent } from '../comparador/comparador.component';
import { AutoComponent } from '../auto.component';

@Component({
  selector: 'app-form-veh',
  templateUrl: './form-veh.component.html',
  styleUrls: ['./form-veh.component.css'],
})
export class FormVehComponent implements OnInit {
  //Datos del formulairo
  tipoVehi: any;
  comparador: ComparadorComponent;
  autoaa: AutoComponent;
  marcaVehi: any;
  modeloVehi: any;
  provin: any;
  usoVehi: any;
  acessorios: any;
  formVehiculo: FormGroup;
  submitted = false;
  submittedModal = false;
  btnShow: boolean = true;
  emailForm: FormGroup;
  

  //------
  allData: any; //obj para enviar los datos al objeto final



  jsonOb: JSON;

  idPar: any; //almacenar id
  backData: boolean = false; // para llenar el formulario si es que se esta dando hacia atras

  hasEquipa: number[] = [];
  hasEquipaText: string[] = [];

  step: number;
  isModalOpen: boolean = false;

  loading: boolean = false; //gif

  //Value para el valor del vehiculo el caso que sea Ley
  carValue: string;

  // Show cilindraje
  showCilindro: boolean = false;
  //Cilindraje value
  cilindValue: string[];
  cilindrajeVal: string;
  cilindrajeID: string;
  //Tipo cilindraje
  cilindraje: object = {
    1: [
      //Auto/St. W.
      'Hasta 6 cilindros',
      'Mas de 6 cilindros',
    ],
    2: [
      //Camionetas
      'Hasta 6 cilindros',
      'Mas de 6 cilindros',
      'Plataneras',
    ],
    3: [
      //Minibus/Autobus
      'Hasta 15 pasajeros',
      '15 a 26 pasajeros',
      'Mas de 26 pasajeros',
    ],
    4: [
      //Camiones
      'Hasta 6 toneladas',
      'Mas de 6 toneladas',
    ],
    6: [
      //Jeep
      'Hasta 6 cilindros',
      'Mas de 6 cilindros',
    ],
    7: [
      //Moto
      'Hasta 250 CC',
      'Mas de 250 CC',
    ],

    //Esto es una prueba
    9: [
      'Hasta 4 cilindros y hasta 5 pasajeros',
      'Mas de 4 cilindros y hasta 9 pasajeros',
    ],
    //-----------------------------
  };

  @Input()
  @Output()
  formOneTalk: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.idPar = params.id;
      if (params.id != null && params.id != '' && params.id != undefined) {
        localStorage.setItem('id', this.idPar);
      }
    });
  }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    window.scrollTo(0, 0);
    if (this.idPar != null) {
      this.http
        .get<any>(
          environment.baseURL + '/cotizador/AutoPendiente?id=' + this.idPar
        )
        .subscribe({
          next: (data) => {
            //   console.log(data);
            this.step = 2;
            this.dataService.Interaccion(
              this.idPar,
              'Se busco la cotizacion por id',
              2
            );
            this.formVehiculo.value.marca = data.datosIngresados.Marca;
            this.formVehiculo.value.modelo = data.datosIngresados.Modelo;
            this.formVehiculo.value.tipoVeh = data.datosIngresados.TipoVehiculo;
            this.cilindrajeVal = data.datosIngresados.Passcode;
            this.formVehiculo.value.anio = data.datosIngresados.Anio;
            this.formVehiculo.value.valorVeh =
              data.datosIngresados.ValorVehiculo;
            this.formVehiculo.value.provincia = data.datosIngresados.Provincia;
            this.formVehiculo.value.usoVeh = data.datosIngresados.Uso;
            this.formVehiculo.value.tipoSeg = data.datosIngresados.TipoSeguro;
            this.dataService.stepOne(
              data.datosIngresados.Marca,
              data.datosIngresados.Modelo,
              data.datosIngresados.TipoVehiculo,
              data.datosIngresados.Passcode,
              data.datosIngresados.Anio,
              data.datosIngresados.ValorVehiculo,
              data.datosIngresados.Provincia,
              data.datosIngresados.Uso,
              data.datosIngresados.EquipamientosNumb, //ojo aqui con los equipamientos, deben de ponerse como van lo del equipamientiosnumb
              data.datosIngresados.Equipamientos,
              data.datosIngresados.TipoSeguro
            );
            this.loading = false;
            this.saveForLatter();
            this.sendInfo();
          },
          error: (error) => {
            this.loading = false;
            this.whatErroIs(error);
          },
        });
    }
    this.dataService.Interaccion(
      0,
      'Se selecciono el producto: Auto',
      0
    );

    this.http
      .get(environment.baseURL + '/vehiculos/TipoVehiculo')
      .subscribe((data) => this.displayTip(data));
    this.http
      .get(environment.baseURL + '/vehiculos/Marca')
      .subscribe((data) => this.displayVeh(data));
    this.http
      .get(environment.baseURL + '/vehiculos/Circulacion')
      .subscribe((data) => this.displayProv(data));
    this.http
      .get(environment.baseURL + '/vehiculos/Actividades')
      .subscribe((data) => this.displayUso(data));
    this.http
      .get(environment.baseURL + '/vehiculos/Accesorios')
      .subscribe((data) => this.displayEquipa(data));

    this.formVehiculo = this.formBuilder.group({
      tipoVeh: ['', Validators.required],
      tipoSeg: ['', Validators.required],
      tipoCil: [''],
      marca: ['', Validators.required],
      modelo: ['', Validators.required],
      anio: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.pattern('[0-9]*'),
        ],
      ],
      valorVeh: [''],
      provincia: ['', Validators.required],
      usoVeh: ['', Validators.required],
      equipamientos: '',
    });
  }

  //Fill form data
  displayTip(data: any) {
    this.tipoVehi = data;
  }
  displayVeh(data: any) {
    this.marcaVehi = data;
  }
  displayProv(data: any) {
    this.provin = data;
  }
  displayUso(data: any) {
    this.usoVehi = data;
  }
  displayEquipa(data: any) {
    this.acessorios = data;
  }

  //Fill cilindraje options
  fillCilindraje(elem: any, bool = true) {
    this.formVehiculo.controls['tipoCil'].reset('');
    if (bool) {
      this.cilindValue = this.cilindraje[elem.value];
      this.cilindrajeID = elem.value;
    } else {
      this.cilindValue = this.cilindraje[elem];
      this.cilindrajeID = elem;
    }
    //console.log(elem.value)
  }

  //change function to get cars models depends on the mark
  showModelo(id: any) {
    this.formVehiculo.controls['modelo'].reset('');
    this.http
      .get(environment.baseURL + '/vehiculos/Modelos?id=' + id)
      .subscribe((data) => (this.modeloVehi = data));
  }

  //Call function to fil form with the older data, in case go back
  ngAfterContentInit() {
    if (this.dataService.fillFormVeh()) {
      this.btnShow = false;
      const temcond: any = document.getElementById('acepTermCond');
      temcond.checked = true;
      this.setDataFrom();
    }
  }

  //Go to step two and hide step one
  sendInfo() {
    // console.log('Emit 1')
    //Este es el original
    this.formOneTalk.emit('true');
  }

  //ngValidation and activated invalid classes
  get f() {
    return this.formVehiculo.controls;
  }

  //Submit form
  onSubmit() {
    ///console.log(this.dataService.allDataParamers.Auto.Equipamientos)
    this.submitted = true;

    // stop here if form is invalid
    if (this.formVehiculo.invalid) {
      return;
    } 
    else 
    {
      //Aqui validamos si el correo ya fue ingresado para rellenar el campo del siguiente formulario
      this.dataService.allTheData.Cliente.correo = this.dataService.allTheData.Cliente.correo == '' ? this.emailForm.value.email : this.dataService.allTheData.Cliente.correo
      this.loading = true; //show gif
      if (this.formVehiculo.value.valorVeh == '' || this.formVehiculo.value.valorVeh == null) 
      {
        this.carValue = '0';
      } 
      else 
      {
        this.carValue = this.formVehiculo.value.valorVeh;
      }
      if (!this.showCilindro)
      {
        this.cilindrajeVal = '';
      } 
      else 
      {
        this.cilindrajeVal = this.formVehiculo.value.tipoCil;
      }
      this.allData = {
        marca_auto_id: this.formVehiculo.value.marca,
        modelo_auto_id: this.formVehiculo.value.modelo,
        Anio: this.formVehiculo.value.anio,
        tipo_garantiasId: this.formVehiculo.value.tipoVeh,
        passcode: this.cilindrajeVal,
        ValorVehiculo: this.carValue,
        UsosGarantiasId: this.formVehiculo.value.usoVeh,
        CirculacionId: this.formVehiculo.value.provincia,
        Accesorios: this.hasEquipa,
      };
      //Prueba
      // console.log(this.dataService.EquipamientosSaved)
      // console.log(this.dataService.EquipamientosSaved.length)
      // console.log(this.hasEquipa)
      // console.log(this.hasEquipa.length)
      // console.log(this.dataService.EquipamientosSaved.some((val, index) => val === this.hasEquipa[index]))
      //console.log(this.dataService.allDataParamers.Auto.Equipamientos.every((val, index) => val === this.hasEquipa[index]))


      if(this.dataService.allDataParamers.Auto.marca           != this.formVehiculo.value.marca          || this.dataService.allDataParamers.Auto.modelo           != this.formVehiculo.value.modelo
        || this.dataService.allDataParamers.Auto.Anio           != this.formVehiculo.value.anio           || this.dataService.allDataParamers.Auto.tipo_vehiculo    != this.formVehiculo.value.tipoVeh || this.dataService.allDataParamers.passcode != this.allData.passcode
        || this.dataService.allDataParamers.Auto.ValorVehiculo  != this.formVehiculo.value.valorVeh       || this.dataService.allDataParamers.Auto.Uso              != this.formVehiculo.value.usoVeh
        || this.dataService.allDataParamers.Auto.Circulacion    != this.formVehiculo.value.provincia      || (this.dataService.EquipamientosSaved.some((val, index) => val === this.hasEquipa[index]) && this.dataService.EquipamientosSaved.length != this.hasEquipa.length)
        || this.dataService.allDataParamers.id_tipoAuto         != this.cilindrajeID                      || this.dataService.allDataParamers.TipoSeguro            != this.formVehiculo.value.tipoSeg
      )
      
      {
        this.dataService.EquipamientosSaved = this.hasEquipa.slice();
        

        this.jsonOb = <JSON>this.allData;
        this.http
          .post<any>(
            environment.baseURL +
              '/cotizar?TipoSeguro=' +
              this.formVehiculo.value.tipoSeg,
            this.jsonOb
          )
          .subscribe({
            next: (data) => {
              // console.log(data)
              //this.dataService.Interaccion(this.dataService.allTheData.Cliente.IdCotizacion,"Se cotizo un", 1)
              //this.dataService.SetDatosCotizacion(datap[0]. parseInt(data[0].IdCotizacion))
              //console.log(data.precios)
              this.dataService.ofertas(data);
              this.loading = false;
              this.sendInfo();
              //console.log("intento de invocar")
              this.getTextSelect();
              this.saveForLatter();
            },
            error: (error) => {
              const marc: any = document.getElementById('marca');
              const veh: any = document.getElementById('tipoVeh');
              const cildra: any = document.getElementById('tipoCil');
              const mod: any = document.getElementById('modelo');
              const pro: any = document.getElementById('provincia');
              const uso: any = document.getElementById('usoVeh');
              this.dataService.Interaccion(
                this.dataService.allTheData.Cliente.IdCotizacion,
                'Se Cotizo un seguro: ' +
                this.formVehiculo.value.tipoSeg +
                ', Vehiculo: ' +
                marc.options[marc.selectedIndex].text +
                ' ' +
                mod.options[mod.selectedIndex].text +
                ' ' +
                this.formVehiculo.value.anio +
                ' ' +
                '$' +
                this.carValue,
                1);

              this.loading = false;
              this.whatErroIs(error);
            },
          });
      }
      else{
        this.formOneTalk.emit('true');
      }
    }
  }

  //Able "cotizar" button
  acepto(elem: any) {
    if (elem.checked) {
      this.btnShow = false;
    } else {
      this.btnShow = true;
    }
  }

  //Eliminate checkbox from the array
  removeItem(array, item) {
    for (var i in array) {
      if (array[i] == item) {
        array.splice(i, 1);
        break;
      }
    }
  }

  //save checkbox
  seeSelect(elem: any) {
    if (elem.checked) {
      this.hasEquipa.push(elem.value);
      this.hasEquipaText.push(elem.nextSibling.innerText);
    } else {
      this.removeItem(this.hasEquipa, elem.value);
      this.removeItem(this.hasEquipaText, elem.nextSibling.innerText);
    }
    //console.log(this.dataService.allDataParamers.Auto.Equipamientos)

  }

  //know error type
  whatErroIs(error) {
    switch (error.status) {
      case 404: {
        this.alert('No encontrado: el recurso solicitado no existe.');
        break;
      }
      case 500: {
        this.alert(
          'Error interno del servidor: se produjo un error genérico en el servidor. Intentelo más tarde.'
        );
        break;
      }
    }
  }
  //Alert message
  alert(mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops... Algo salio mal',
      text: mensaje,
    });
  }

  //get literally the values from the form and send to the object
  getTextSelect() {
    const marc: any = document.getElementById('marca');
    const veh: any = document.getElementById('tipoVeh');
    const cildra: any = document.getElementById('tipoCil');
    const mod: any = document.getElementById('modelo');
    const pro: any = document.getElementById('provincia');
    const uso: any = document.getElementById('usoVeh');

    let cilindType = !this.showCilindro
      ? ''
      : cildra.options[cildra.selectedIndex].text;

    this.dataService.stepOne(
      marc.options[marc.selectedIndex].text,
      mod.options[mod.selectedIndex].text,
      veh.options[veh.selectedIndex].text,
      cilindType,
      this.formVehiculo.value.anio,
      this.formVehiculo.value.valorVeh,
      pro.options[pro.selectedIndex].text,
      uso.options[uso.selectedIndex].text,
      this.hasEquipa,
      this.hasEquipaText,
      this.formVehiculo.value.tipoSeg
    );
    //console.log("Se llamo")
    this.dataService.Interaccion(
      0,
      'Se Cotizo un seguro: ' +
        this.formVehiculo.value.tipoSeg +
        ', Vehiculo: ' +
        marc.options[marc.selectedIndex].text +
        ' ' +
        mod.options[mod.selectedIndex].text +
        ' ' +
        this.formVehiculo.value.anio +
        ' ' +
        '$' +
        this.carValue,
      1
    );
  }

  //save values
  saveForLatter() {
    if (!this.showCilindro) {
      this.cilindrajeVal = '';
    } else {
      this.cilindrajeVal = this.formVehiculo.value.tipoCil;
    }
    //console.log(this.step)
    this.dataService.saveData(
      this.cilindrajeID,
      this.formVehiculo.value.marca,
      this.formVehiculo.value.modelo,
      this.formVehiculo.value.tipoVeh,
      this.cilindrajeVal,
      this.formVehiculo.value.anio,
      this.formVehiculo.value.valorVeh,
      this.formVehiculo.value.provincia,
      this.formVehiculo.value.usoVeh,
      this.hasEquipa,
      this.formVehiculo.value.tipoSeg,
      this.step
    );
    this.dataService.fillVeh();
  }

  //Fill from with old data in case go back <=
  setDataFrom() {
    const datos = this.dataService.getDataVeh();
    this.http
      .get(environment.baseURL + '/vehiculos/Modelos?id=' + datos.Auto.marca)
      .subscribe((data) => (this.modeloVehi = data));
    this.cilindrajeID = datos.id_tipoAuto;
    let cilindrosSave = datos.passcode;
    if (datos.passcode == '') {
      this.showCilindro = false;
      this.validateInput(false, 'tipoCil');
    } else {
      this.showCilindro = true;
      this.cilindValue = this.cilindraje[datos.id_tipoAuto];
      this.validateInput(true, 'tipoCil');
    }
    //console.log('cilindrossave: ' + cilindrosSave)
    this.formVehiculo = this.formBuilder.group({
      tipoVeh: [datos.Auto.tipo_vehiculo, Validators.required],
      tipoCil: [cilindrosSave],
      anio: [
        datos.Auto.Anio,
        [Validators.required, Validators.pattern('[0-9]*')],
      ],
      tipoSeg: [datos.TipoSeguro, Validators.required],
      marca: [datos.Auto.marca, Validators.required],
      valorVeh: [datos.Auto.ValorVehiculo],
      provincia: [datos.Auto.Circulacion, Validators.required],
      usoVeh: [datos.Auto.Uso, Validators.required],
      equipamientos: this.setEquipa(datos.Auto.Equipamientos),
      modelo: [datos.Auto.modelo, Validators.required],
    });
  }
  //Check Equipaments
  setEquipa(obj: any) {
    const textEquipamiento = this.dataService.seeDataForm();
    this.hasEquipa = obj;
    this.hasEquipaText = [''];

    setTimeout(function () {
      for (let i in obj) {
        let id: string = 'opcion' + obj[i];
        let elemento: any = document.getElementById(id);
        elemento.checked = true;
      }
    }, 2000);
  }

  /** set input required depends of type if insurance (value and subcategory)
   * show or hide subcategory
   **/
  seeTypeSeg(e: any) {
    if (e.value == 'FULL') {
      this.validateInput(true, 'valorVeh');
      this.validateInput(false, 'tipoCil');
      this.showCilindro = false;
      this.cilindValue = [];
    } else {
      this.fillCilindraje(this.formVehiculo.value.tipoVeh, false);
      this.validateInput(false, 'valorVeh');
      this.validateInput(true, 'tipoCil');
      this.showCilindro = true;
    }
  }

  //set conditional validation for the input depends if afirmative
  validateInput(status, elemento) {
    if (status) {
      this.formVehiculo.controls[elemento].setValidators([Validators.required]);
      this.formVehiculo.controls[elemento].updateValueAndValidity();
    } else {
      this.formVehiculo.controls[elemento].setValidators(null);
      this.formVehiculo.controls[elemento].updateValueAndValidity();
    }
  }

  //ngValidation and activated invalid classes
  get emailF() {
    return this.emailForm.controls;
  }

  AbrirModal() {
    this.submitted = true;
    if (this.formVehiculo.invalid) {
      return;
    } else {
      if (this.dataService.esPrimeraVez == true) {
        this.dataService.esPrimeraVez = false;
        this.isModalOpen = true;
      } else this.onSubmit();
    }
  }

  CerrarModal() {
    this.isModalOpen = false;
    this.dataService.esPrimeraVez = true;
  }

  OptenerIpYCorreo() {
    this.submittedModal = true;
    // stop here if form is invalid
    if (this.emailForm.invalid) {
      return;
    } else {
      //console.log(this.emailForm.value.email)
      this.http
        .get(
          environment.baseURL +
            '/AgregarCorreo?correo=' +
            this.emailForm.value.email +
            '&ip=' +
            this.dataService.allTheData.Cliente.IpUsuario +
            '&IdCtz=0'
        )
        .subscribe({
          next: (data: any) => {
            this.isModalOpen = false;
            this.onSubmit();
          },
          error: (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              html:
                'Algo salio mal, por favor vuelva a intentarlo.<br>' +
                '<br>Si el error persiste por favor comuniquese con nosotros al 809-620-2524 o ' +
                '<a href="https://api.whatsapp.com/send?phone=18096202524&text=Hola, no he podido ver los servicios disponibles en el incio de la web ASEGURATE.COM.DO y necesito su asistencia." target="_blank">escríbenos a WhatsApp</a>',
            });
          },
        });
    }
  }

  LogClick() {}

  //Este no funciona
  // @HostListener('window:mouseleave', ['$event'])
  // onMouseLeave(event: MouseEvent): void {
  //   console.log("Se salio 1")
  // }

  @HostListener('window:blur', ['$event'])
  onWindowBlur(event: FocusEvent): void {
    //console.log('Se salio Vehiculo');
    this.dataService.Interaccion(0, 'El usuario clikeo fuera de la pestaña', 1);
  }
}
